import { OverlayTrigger, Popover } from "react-bootstrap";
import { createPortal } from "react-dom";
import Button from "../../Button/Button";
import "./testDriveOverlay.css";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { formatDateForEvents } from "../../../utils/formatDateTime";
import { addHours } from "date-fns";

const TestDriveOverlay = ({ buttonRef, el, event, modalAction, editMode = "create", handleClickShowNoShow }) => {
  const { t } = useTranslation();
  const handleClick = (action) => modalAction(action);
  const role = useSelector(state => state.userInfo?.accessLevel)
  const popoverTop = (
    <Popover className="tdoverlay" id="popover-positioned-top" title={getTranslation("Edit Appointment", t)}>
      <Button disabled={event.eventType !== 1} onClick={(e) => handleClick(editMode)} type="edit">
        {getTranslation("Edit", t)}
      </Button>
      <Button disabled={event.eventType !== 1} onClick={(e) => handleClick("cancel")} type="trash">
        {getTranslation("Cancel", t)}
      </Button>
    </Popover>
  );

  const enableCondition = new Date() > event.eventStartDate && event.eventType === 1;
  const clickLimitCondition = new Date() <= addHours(new Date(event.eventEndDate), 24);

  const showNoShowPopOver = (
    <Popover className="sns-overlay d-flex flex-column justify-content-between">
      <div className="d-flex justify-content-between lh-sm">
        <div className="upper-info">
          <p>{formatDateForEvents(event.eventStartDate, event.eventEndDate)}</p>
          <p>{getTranslation("Test Drive", t)}</p>
          <p>{event.eventData.brandName}</p>
        </div>
        {(event.eventType !== 1 || clickLimitCondition) && <div className="sns-btns d-flex flex-column gap-1">
          <Button disabled={!enableCondition} onClick={() => handleClickShowNoShow("show")} type="show">{getTranslation("Show", t)}</Button>
          <Button disabled={!enableCondition} onClick={() => handleClickShowNoShow("no-show")} type="no-show">{getTranslation("No Show", t)}</Button>
        </div>}
      </div>
      <div className="lower-info">
        <p>{getTranslation("Customer", t)}: {event.eventTitle}</p>
        <p>{getTranslation("Customer document no.", t)}: {event.eventData.documentNumber}</p>
      </div>
      <div className="d-flex gap-2 mt-3">
        <Button disabled={event.eventType !== 1 || !clickLimitCondition} onClick={(e) => handleClick(editMode)} type="edit">
          {getTranslation("Edit", t)}
        </Button>
        <Button disabled={event.eventType !== 1 || !clickLimitCondition} onClick={(e) => handleClick("cancel")} type="trash">
          {getTranslation("Cancel", t)}
        </Button>
      </div>
    </Popover>
  )

  const overlay = (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="top"
      overlay={role === 1 ? showNoShowPopOver : popoverTop}
    >
      <div ref={buttonRef} className="custom-slot"></div>
    </OverlayTrigger>
  );

  return createPortal(overlay, el.current);
};

export default TestDriveOverlay;
