import React from "react";
import "./disclaimerModal.css";
import { Dialog } from "@mui/material";
import { getTranslation } from "../../../utils/getTranslation";
import Button from "../../Button/Button";
import { useTranslation } from "react-i18next";

const DisclaimerModal = ({ open, toggleModal, disclaimerType, textContent }) => {
  const { t } = useTranslation();

  return (
    <Dialog className="disclaimer-modal" open={open} onClose={!toggleModal}>
      <h2>
        {disclaimerType === "terms"
          ? getTranslation("Terms and conditions", t)
          : getTranslation("Personal data protection", t)}
      </h2>
      <span dangerouslySetInnerHTML={{ __html: textContent }}>
      </span>
      <Button
        onClick={() => toggleModal(disclaimerType)}
        children={getTranslation("Accept", t)}
        type="primary"
      />
    </Dialog>
  );
};

export default DisclaimerModal;
