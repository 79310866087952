import reasons from "../../../../db/reasons";
import Dropdown from "../../../Dropdown/Dropdown";
import "./blockReasonSection.css";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const BlockReasonSection = ({
  className,
  payload,
  errors,
  handleChangeDropdownCheckbox
}) => {
  const { t } = useTranslation();
  return (
    <div className={className + " block-reason"}>
      <h3>{getTranslation("Reason for blocking", t)}</h3>
      <span>{getTranslation("Select the reason for blocking", t)}</span>
      <Dropdown
        data={reasons.map((item) => ({...item, name: getTranslation(item.name, t)}))}
        error={errors.reason}
        name="reason"
        handleChange={handleChangeDropdownCheckbox}
        textField="name"
        valueField="id"
        value={payload.reason}
        label={getTranslation("Reason", t)}
      />
    </div>
  );
};

export default BlockReasonSection;
