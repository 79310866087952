import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_CALENDAR_EVENTS } from "../actions/types";
import { getCalendarEventsApi } from "../api/handler";
import { setCalendarEvents } from "../actions";

function* handleLoadCalendarEvents(args) {
  try {
    const response = yield call(getCalendarEventsApi, args.params);
    if (response.status === 200) {
      const responseData = response.data.data.map((d) => {
        return {
          ...d,
          eventStartDate: new Date(d.eventStartDate),
          eventEndDate: new Date(d.eventEndDate),
        };
      });
      yield put(setCalendarEvents(responseData));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* calendarSaga() {
  yield all([takeLatest(GET_CALENDAR_EVENTS, handleLoadCalendarEvents)]);
}
