import { takeLatest, all, call, put } from "redux-saga/effects";
import { getCommonCitiesListApi } from "../api/handler";
import { setCitiesList } from "../actions";
import { GET_CITIES_LIST } from "../actions/types";

function* handleLoadCitiesList(args) {
  try {
    const response = yield call(getCommonCitiesListApi, args.params);
    if (response.status === 200) {
      yield put(setCitiesList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* citiesSaga() {
  yield all([takeLatest(GET_CITIES_LIST, handleLoadCitiesList)]);
}
