import "./testDriveCalendarFilters.css";
import Button from "../../../Button/Button";
import Dropdown from "../../../Dropdown/Dropdown";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  loadBrandList,
  loadCommonShowroomList,
  loadFuelTypeList,
  loadModelList,
  loadVariantList,
  loadVinList,
  setCommonShowroomList,
  setFuelTypeList,
  setModelList,
  setVariantList,
  setVinList
} from "../../../../redux-sagas/actions";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import fetchClient from "../../../../services/fetch";

const TestDriveCalendarFilters = ({ filters, setFilters, role, getBrandSettings, setBrandSettings, setShowroomInfo, setVehicleInfo }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const brandList = useSelector((state) => state.brandList);
  const showroomList = useSelector((state) => state.commonShowroomList);
  const modelList = useSelector((state) => state.modelList);
  const variantList = useSelector((state) => state.variantList);
  const fuelTypeList = useSelector((state) => state.fuelTypeList);
  const vinList = useSelector((state) => state.vinList);

  const handleFiltersChange = (e) => {
    const { value, name } = e.target;
    let nameValue = "";
    switch (name) {
      case "brand":
        nameValue = brandList.data.find((e) => e.id === value).name;
        setFilters({
          [name]: { id: value, name: nameValue },
          model: { id: "" },
          variant: { id: "" },
          fuelType: { id: "" },
          showroom: { id: "" },
          vin: { id: "" }
        });

        getBrandSettings(value);

        break;
      case "showroom":
        nameValue = showroomList.data.find((e) => e.id === value).name;
        setFilters({
          ...filters,
          [name]: { id: value, name: nameValue },
          model: { id: "" },
          variant: { id: "" },
          fuelType: { id: "" },
          vin: { id: "" }
        });

        fetchClient().get(`/showrooms/${value}`)
          .then(res => {
            setShowroomInfo(res.data.data);
          });
        break;
      case "model":
        nameValue = modelList.data.find((e) => e.id === value).name;
        setFilters({
          ...filters,
          [name]: { id: value, name: nameValue },
          variant: { id: "" },
          fuelType: { id: "" },
          vin: { id: "" }
        });
        break;
      case "variant":
        nameValue = variantList.data.find((e) => e.id === value).name;
        setFilters({
          ...filters,
          [name]: { id: value, name: nameValue },
          fuelType: { id: "" },
          vin: { id: "" }
        });
        break;
      case "fuelType":
        nameValue = fuelTypeList.data.find((e) => e.id === value).name;
        setFilters({
          ...filters,
          [name]: { id: value, name: nameValue },
          vin: { id: "" }
        });
        break;
      case "vin":
        nameValue = vinList.data.find((e) => e.id === value).name;
        setFilters({ ...filters, [name]: { id: value, name: nameValue } });
        fetchClient()
          .get(`/commondata/vehicles/info?vehicleId=${value}`)
          .then((response) => {
            const { data } = response.data;
            setVehicleInfo(data);
          })
          .catch((err) => {
            console.error(err);
          });
        break;
      default:
        break;
    }
  };

  const handleClear = () => {
    setFilters({
      brand: { id: "" },
      model: { id: "" },
      variant: { id: "" },
      fuelType: { id: "" },
      showroom: { id: "" },
      vin: { id: "" }
    });

    setVehicleInfo(null);
    setBrandSettings(null);
  };

  const handleOnOpen = (e, name) => {
    switch (name) {
      case "brand":
        dispatch(loadBrandList(""));
        break;
      case "showroom":
        if (filters.brand.id) {
          dispatch(loadCommonShowroomList(`?brandId=${filters.brand.id}`));
        } else {
          dispatch(setCommonShowroomList([]));
        }
        break;
      case "model":
        const c = role === 1
          ? filters.brand.id
          : filters.brand.id && filters.showroom.id;
        if (c) {
          const f = filters.showroom.id
            ? `?brandId=${filters.brand.id}&showroomId=${filters.showroom.id}`
            : `?brandId=${filters.brand.id}`;
          dispatch(loadModelList(f));
        } else {
          dispatch(setModelList([]));
        }
        break;
      case "variant":
        if (filters.model.id && filters.brand.id) {
          const f = filters.showroom.id
            ? `?brandId=${filters.brand.id}&modelId=${filters.model.id}&showroomId=${filters.showroom.id}`
            : `?brandId=${filters.brand.id}&modelId=${filters.model.id}`;
          dispatch(loadVariantList(f));
        } else {
          dispatch(setVariantList([]));
        }
        break;
      case "fuelType":
        if (filters.variant.id && filters.model.id && filters.brand.id) {
          const f = filters.showroom.id
            ? `?brandId=${filters.brand.id}&modelId=${filters.model.id}&variantId=${filters.variant.id}&showroomId=${filters.showroom.id}`
            : `?brandId=${filters.brand.id}&modelId=${filters.model.id}&variantId=${filters.variant.id}`;
          dispatch(loadFuelTypeList(f));
        } else {
          dispatch(setFuelTypeList([]));
        }
        break;
      case "vin":
        const condition =
          role === 1 ? filters.fuelType.id : filters.showroom.id;
        if (
          condition &&
          filters.variant.id &&
          filters.model.id &&
          filters.brand.id
        ) {
          dispatch(
            loadVinList(
              `?brandId=${filters.brand.id}&modelId=${filters.model.id
              }&variantId=${filters.variant.id}&${role === 1
                ? `fueltypeId=${filters.fuelType.id}`
                : `showroomId=${filters.showroom.id}`
              }`
            )
          );
        } else {
          dispatch(setVinList([]));
        }
        break;
      default:
        break;
    }
  };

  return (
    <div className="test-drive-filters-container">
      <Grid container spacing={3} className="justify-content-between">
        <Grid item xs={12} sm={8} md={5} lg={2}>
          <Dropdown
            label={"*" + getTranslation("Brand", t)}
            data={brandList.data}
            valueField="id"
            textField="name"
            value={filters.brand.id}
            name="brand"
            handleChange={handleFiltersChange}
            onOpen={(e) => handleOnOpen(e, "brand")}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          {role === 2 ? (
            <Dropdown
              label="*Showroom"
              data={showroomList.data}
              valueField="id"
              textField="name"
              value={filters.showroom.id}
              name="showroom"
              handleChange={handleFiltersChange}
              onOpen={(e) => handleOnOpen(e, "showroom")}
            />
          ) : (
            <Dropdown
              label={"*" + getTranslation("Model", t)}
              data={modelList.data}
              valueField="id"
              textField="name"
              value={filters.model.id}
              name="model"
              handleChange={handleFiltersChange}
              onOpen={(e) => handleOnOpen(e, "model")}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          {role === 2 ? (
            <Dropdown
              label={"*" + getTranslation("Model", t)}
              data={modelList.data}
              valueField="id"
              textField="name"
              value={filters.model.id}
              name="model"
              handleChange={handleFiltersChange}
              onOpen={(e) => handleOnOpen(e, "model")}
            />
          ) : (
            <Dropdown
              label={"*" + getTranslation("Variant", t)}
              data={variantList.data}
              valueField="id"
              textField="name"
              value={filters.variant.id}
              name="variant"
              handleChange={handleFiltersChange}
              onOpen={(e) => handleOnOpen(e, "variant")}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          {role === 2 ? (
            <Dropdown
              label={"*" + getTranslation("Variant", t)}
              data={variantList.data}
              valueField="id"
              textField="name"
              value={filters.variant.id}
              name="variant"
              handleChange={handleFiltersChange}
              onOpen={(e) => handleOnOpen(e, "variant")}
            />
          ) : (
            <Dropdown
              label={"*" + getTranslation("Fuel Type", t)}
              data={fuelTypeList.data?.map((item) => ({
                ...item,
                name: getTranslation(item.name, t)
              }))}
              valueField="id"
              textField="name"
              value={filters.fuelType.id}
              name="fuelType"
              handleChange={handleFiltersChange}
              onOpen={(e) => handleOnOpen(e, "fuelType")}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          <Dropdown
            label={"*" + getTranslation("VIN", t)}
            data={vinList.data}
            valueField="id"
            textField="name"
            value={filters.vin.id}
            name="vin"
            handleChange={handleFiltersChange}
            onOpen={(e) => handleOnOpen(e, "vin")}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2} sx={{ marginTop: 1 }}>
          <Button
            type="secondary"
            children={getTranslation("Clean", t)}
            onClick={handleClear}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TestDriveCalendarFilters;
