import { Switch } from "@mui/material";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const GridStatusCell = ({ row, handleChangeStatus }) => {
  const { t } = useTranslation();

  return (
    <div className="switch-cell">
      <Switch
        onChange={(e) => {
          handleChangeStatus(row);
          e.target.checked = !row.isActive;
        }}
        checked={row.isActive}
      />
      <span>{getTranslation(row.isActive ? "Active" : "Inactive", t)}</span>
    </div>
  );
};

export default GridStatusCell;
