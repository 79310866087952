import React, { useRef, useState } from "react";
import "./uploadSection.css";
import { MdOutlineUploadFile } from "react-icons/md";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
import { getTranslation } from "../.../../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const UploadSection = ({
  payload,
  handleImageSelect,
  handleDrag,
  handleDragLeave,
  handleDrop,
  handleRemoveImage,
  error,
  errors,
  isDrag,
  uploadImg,
  mode
}) => {
  const { t } = useTranslation();
  const imageInputRef = useRef(null);

  return (
    <div className="demo-image-upload">
      <h4>{getTranslation("Vehicle image", t)}</h4>

      <div
        className={`upload-box ${error || (errors.uploadImg && "error")}`}
        onDragOver={handleDrag}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <div>
          <MdOutlineUploadFile
            className={error || (errors.uploadImg && "error")}
          />
        </div>
        <div>
          {isDrag ? (
            <>
              <span className="drag-text">
                {getTranslation("Drag here to upload", t)}
              </span>
            </>
          ) : (
            <>
              <span onClick={() => imageInputRef?.current?.click()}>
                {getTranslation("Select file", t)}
              </span>
              <input
                type="file"
                hidden
                onChange={handleImageSelect}
                ref={imageInputRef}
                accept="image/png, image/jpeg, image/jpg"
              />
              <span> {getTranslation("or drag here to upload", t)}</span>
            </>
          )}
        </div>
        <p>PNG, jpg - 400 x 400px (max. 3MB)</p>
        {uploadImg && (
          <div className="image-uploaded-box">
            <p>{uploadImg?.name}</p>
            <IoMdCheckmarkCircle className="check-icon" />
            <FiTrash2 className="trash-icon" onClick={handleRemoveImage} />
          </div>
        )}
        {error ||
          (errors.uploadImg && (
            <span className="error-msg">
              {getTranslation("Invalid file, try again.", t)}
            </span>
          ))}
      </div>
    </div>
  );
};

export default UploadSection;
