import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import Button from "../../../../components/Button/Button";
import { formatDate } from "../../../../utils/formatDateTime";
import "./generalFilter.css";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";

const GeneralFilter = ({ generalQueryParams, setGeneralQueryParams }) => {
  const [filters, setFilters] = useState({});
  const { t, i18n } = useTranslation();
  const handleChange = (name, newValue) => {
    setFilters({ ...filters, [name]: newValue });
    setGeneralQueryParams({
      ...generalQueryParams,
      [name]: formatDate(newValue)
    });
  };

  const handleClearFilters = () => {
    setFilters({});
    setGeneralQueryParams({
      ...generalQueryParams,
      startDate: "",
      endDate: ""
    });
  };

  return (
    <div className="general">
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <DatePicker
          className="calendar-input"
          onChange={(newValue) => handleChange("startDate", newValue)}
          value={filters.startDate ?? null}
          label={getTranslation("Start Date", t)}
        />
        <DatePicker
          className="calendar-input"
          onChange={(newValue) => handleChange("endDate", newValue)}
          value={filters.endDate ?? null}
          label={getTranslation("End Date", t)}
        />
      </LocalizationProvider>
      <Button onClick={handleClearFilters} type="secondary">
        {getTranslation("Clean", t)}
      </Button>
    </div>
  );
};

export default GeneralFilter;
