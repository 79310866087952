import { Dialog } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import InformationSection from "./InformationSection/InformationSection";
import HourSection from "./HourSection/HourSection";
import StatusSection from "./StatusSection/StatusSection";
import Button from "../../Button/Button";
import dayList from "../../../db/dayList";
import { createNewDates, formatObjTime } from "../../../utils/formatDateTime";
import fetchClient from "../../../services/fetch";
import "./createShowroomModal.css";
import { useDispatch, useSelector } from "react-redux";
import { getShowroomList, toggleNotification } from "../../../redux-sagas/actions";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const CreateShowroomModal = ({ open, mode, toggleModal, selected, buildQuery }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const marketId = useSelector((state) => state.userInfo?.marketId);
	const initialized = useRef(false);
	const shouldSubmit = useRef(false);
	const [errors, setErrors] = useState({});
	const [payload, setPayload] = useState({});
	const [dropdownData, setDropdownData] = useState({
		cities: [],
		brands: []
	});
	const hourData = dayList.map((item) => ({
		...item,
		display: getTranslation(item.display, t),
		[item.day + "Enabled"]: false,
		[item.day + "StartTime"]: undefined,
		[item.day + "EndTime"]: undefined
	}));

	const getDropdownData = (name) =>
		fetchClient()
			.get(`/commondata/${name}`)
			.then((res) =>
				setDropdownData((prevState) => ({
					...prevState,
					[name]:
						name === "brands"
							? [{ name: getTranslation("All brands", t), id: 0 }, ...res.data.data]
							: res.data.data
				}))
			)
			.catch((err) => console.log(err));

	const initCreate = () => {
		const combinedData = () => {
			const newObj = {
				copyMondaySchedule: false,
				isActive: true,
				locationUrl: "https://maps.google.com"
			};
			hourData.forEach((data) => {
				for (const key in data) {
					if (key.includes("Time") || key.includes("Enabled")) {
						newObj[key] = data[key];
					}
				}
			});

			return newObj;
		};

		setPayload(combinedData());
	};

	const initEdit = () =>
		fetchClient()
			.get(`/showrooms/${selected.showroomId}`)
			.then((res) => {
				const newData = {
					...res.data.data,
					brands: res.data.data.brands
						.map((brand) => brand.brandId)
						.concat(dropdownData.brands.length - 1 === res.data.data.brands.length ? 0 : [])
				};
				setPayload(createNewDates(newData));
				initialized.current = true;
			})
			.catch((err) => console.log(err));

	const sendRequest = (data) =>
		(mode === "create" ? fetchClient().post : fetchClient().put)(
			`/showrooms${mode === "edit" ? "/" + selected.showroomId : ""}`,
			data
		)
			.then(() => {
				dispatch(getShowroomList(buildQuery()));
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: getTranslation("The showroom", t),
						highlight1: mode === "create" ? payload.showroomName : selected.showroomName,
						content2: `${getTranslation("has been", t)} ${
							mode === "create" ? t("created_el") : t("edited_el")
						} ${getTranslation("successfully", t)}.`,
						highlight2: ""
					})
				);
			})
			.catch((err) => {
				let errMsg = err.response.data.message;
				if (errMsg.includes("Showroom Name")) {
					errMsg = `${getTranslation("Showroom Name", t)} ${errMsg.slice(
						14,
						errMsg.indexOf("already") - 1
					)} ${getTranslation("already exists.", t)}`;
				}

				dispatch(
					toggleNotification({
						isOpen: true,
						type: "error",
						content1: getTranslation(errMsg, t),
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				);
			});

	const handleSubmitClick = () => {
		validate();
		shouldSubmit.current = true;
	};

	const sendData = () => {
		const finalData = formatObjTime({
			...payload,
			marketId,
			brands: payload.brands.filter((id) => id !== 0)
		});
		sendRequest(finalData);
		toggleModal();
	};

	useEffect(() => {
		if (mode === "create") initCreate();
		else {
			getDropdownData("brands");
			getDropdownData("cities");
		}
	}, []);

	useEffect(() => {
		if (
			mode === "edit" &&
			dropdownData.brands.length > 0 &&
			dropdownData.cities.length > 0 &&
			!initialized.current
		)
			initEdit();
	}, [dropdownData]);

	useEffect(() => {
		if (!Object.keys(errors).length && shouldSubmit.current) sendData();
	}, [errors]);

	const handleTextSelectChange = (e) => setPayload({ ...payload, [e.target.name]: e.target.value });

	const handleMultiselectChange = (e) => {
		const { value } = e.target;

		const selectAll = value.includes(0);
		if (selectAll) {
			// select all brand options
			if (!payload.brands || !payload.brands.includes(0))
				setPayload({
					...payload,
					brands: dropdownData.brands.map((data) => data.id)
				});
			// deselect the select all id and the removed item id
			else setPayload({ ...payload, brands: value.filter((id) => id !== 0) });
		} else {
			// deselect all brands when all brands are selected
			if (value.length === dropdownData.brands.length - 1 && payload.brands?.includes(0))
				setPayload({ ...payload, brands: [] });
			// if all brand options are selected aside from select all
			else if (value.length === dropdownData.brands.length - 1 && !payload.brands?.includes(0))
				setPayload({ ...payload, brands: value.concat(0) });
			// select as how it normally should be
			else
				setPayload({
					...payload,
					brands: typeof value === "string" ? value.split(",") : value
				});
		}
	};

	const handleSwitchChange = (e) => {
		const newObj = { ...payload };
		const { name, checked } = e.target;
		if (name === "copyMondaySchedule" && checked) {
			for (const key in newObj) {
				if ((key.includes("Time") || key.includes("Enabled")) && !key.includes("monday")) {
					newObj[key] = newObj["monday" + key.slice(key.indexOf("day") + 3)];
				}
			}
		} else if (name === "mondayEnabled" && newObj.copyMondaySchedule) {
			for (const key in newObj) if (key.includes("Enabled")) newObj[key] = checked;
		} else if (newObj.copyMondaySchedule && !name.includes("monday") && name.includes("Enabled"))
			newObj.copyMondaySchedule = false;
		setPayload({ ...newObj, [name]: checked });
	};

	const handleTimepickerChange = (newValue, name) => {
		const newObj = { ...payload };
		if (name.includes("monday") && name.includes("Time") && newObj.copyMondaySchedule) {
			for (const key in newObj)
				if (key.includes(name.slice(name.indexOf("day") + 3))) newObj[key] = newValue;
		} else if (newObj.copyMondaySchedule && !name.includes("monday"))
			newObj.copyMondaySchedule = false;
		setPayload({ ...newObj, [name]: newValue });
	};

	const validate = () => {
		const err = {};
		if (!payload.showroomName) err.showroomName = true;
		if (!payload.cityId) err.cityId = true;
		if (!payload.address) err.address = true;
		if (!payload.brands || !payload.brands.length) err.brands = true;

		let hasEnabled = 0;
		hourData.forEach((data) => {
			for (const key in data) {
				const day = data.day;
				if (key.includes("Enabled") && payload[day + "Enabled"]) {
					hasEnabled++;
					if (!payload[day + "StartTime"]) err[day + "StartTime"] = true;
					if (!payload[day + "EndTime"]) err[day + "EndTime"] = true;
				}
			}
		});

		if (!hasEnabled) err.hours = true;
		setErrors(err);
	};

	return (
		<Dialog
			className='create-showroom'
			open={open}
			onClose={toggleModal}
		>
			<h2>{getTranslation(mode === "create" ? "Create" : "Edit", t)} Showroom</h2>
			<InformationSection
				dropdownData={dropdownData}
				errors={errors}
				getDropdownData={getDropdownData}
				payload={payload}
				handleTextSelectChange={handleTextSelectChange}
				handleMultiselectChange={handleMultiselectChange}
			/>
			<HourSection
				hourData={hourData}
				payload={payload}
				errors={errors}
				handleTimepickerChange={handleTimepickerChange}
				handleSwitchChange={handleSwitchChange}
			/>
			<StatusSection
				payload={payload}
				handleSwitchChange={handleSwitchChange}
			/>
			{errors.hours && (
				<span className='error'>{getTranslation("Enable at least one day.", t)}</span>
			)}
			<div className='btn-group'>
				<Button
					onClick={toggleModal}
					type='secondary'
				>
					{getTranslation("Cancel", t)}
				</Button>
				<Button
					onClick={handleSubmitClick}
					type='primary'
				>
					{getTranslation("Accept", t)}
				</Button>
			</div>
		</Dialog>
	);
};

export default CreateShowroomModal;
