import Dropdown from "../../../../components/Dropdown/Dropdown";
import Button from "../../../../components/Button/Button";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import "./blockShowroomFilter.css";
import { useEffect } from "react";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";

const BlockShowroomFilter = ({
  setShowroomName,
  fetchShowroomData,
  view,
  showroomName,
  setShowroomData,
  showroomData,
  filters,
  handleFiltersChange,
  handleClearFilters,
  handleSearchFilters,
  resetPageToZero
}) => {
  const { t, i18n } = useTranslation();

  const handleOpenDropdown = () =>
    fetchShowroomData((value) => setShowroomData(value));

  const handleChange = (e, newValue = undefined) => {
    handleFiltersChange({
      ...filters,
      [e.target.name]:
        e.target.name === "showroomName" ? e.target.value : newValue
    });
    if (e.target.name === "showroomName") setShowroomName(e.target.value);
    resetPageToZero();
  };

  useEffect(() => {
    const showroomNames = showroomData.map((item) => item.name);
    if (view === "grid" && showroomName && showroomNames.includes(showroomName)) {
      handleFiltersChange({ ...filters, showroomName });
    } else {
      handleFiltersChange({ ...filters, showroomName: "" });
      setShowroomName("");
    }
  }, [view]);

  return (
    <div className="filters">
      <span>{getTranslation("Select filter", t)}</span>
      <div className="row">
        <Dropdown
          value={filters.showroomName}
          data={showroomData}
          handleChange={handleChange}
          onOpen={handleOpenDropdown}
          valueField="name"
          textField="name"
          name="showroomName"
          className="col-xl-3 ps-0 pe-sm-0 pe-xl-5"
          label="Showroom"
        />
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <DatePicker
            value={filters.startDate ?? null}
            className="calendar-input col-xl-3 ps-0 pe-sm-0 pe-xl-5"
            label={getTranslation("Start Date", t)}
            onChange={(newValue) =>
              handleChange({ target: { name: "startDate" } }, newValue)
            }
          />
          <DatePicker
            value={filters.endDate ?? null}
            className="calendar-input col-xl-3 ps-0 pe-sm-0 pe-xl-5 mb-4 mb-xl-0"
            label={getTranslation("End Date", t)}
            onChange={(newValue) =>
              handleChange({ target: { name: "endDate" } }, newValue)
            }
          />
        </LocalizationProvider>
        <div className="btns col-xl-3 px-0 d-flex flex-wrap flex-xxl-nowrap justify-content-xl-between">
          <Button onClick={() => handleClearFilters({})} type="secondary me-2 me-xl-0">
            {getTranslation("Clean", t)}
          </Button>
          <Button onClick={handleSearchFilters} type="primary">
            {getTranslation("Search", t)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlockShowroomFilter;
