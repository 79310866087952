import React from "react";
import "./blockShowroomFilterCalendar.css";
import TextInput from "../../../../components/TextInput/TextInput";
import { IoClose } from "react-icons/io5";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const BlockShowroomFilterCalendar = ({
  showroomName,
  handleClear,
  handleChange
}) => {
  const { t } = useTranslation();
  return (
    <div className="calendar-filter d-flex align-items-end justify-content-between">
      <div className="d-flex align-items-end gap-3">
        <TextInput
          value={showroomName}
          handleChange={handleChange}
          label="Showroom"
        />
        <div onClick={handleClear} className="close-icon-box">
          <IoClose className="close-icon" />
        </div>
      </div>
      <div className="legend-group d-flex align-items-end">
        <div className="d-flex gap-2 align-items-center">
          <div className="legend-1"></div>
          <span>{getTranslation("With availability", t)}</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="legend-2"></div>
          <span>{getTranslation("Block", t)}</span>
        </div>
      </div>
    </div>
  );
};

export default BlockShowroomFilterCalendar;
