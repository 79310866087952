import { useDispatch } from "react-redux";
import { setAuth, setUserSettings } from "../../../redux-sagas/actions";
import "./landing.css";
import Button from "../../../components/Button/Button";
import inchcapeLogoFill from "../../../assets/images/inchcape-logo-fill.svg";
import Cookie from "../../../services/Cookies";
import fetchClient from "../../../services/fetch"
import { useState } from "react";

const Landing = () => {
  const dispatch = useDispatch();
  const handleLogin = () => {
    fetchClient()
      .get("/sso")
      .then((response) => {
        if (response.data) {
          window.location.href = response.data;
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="landing-main">
      <div className="landing-container">
        <img src={inchcapeLogoFill} alt="Inchcape Logo" />
        <div>
          <h1>Test Drive</h1>
          <Button onClick={handleLogin} type="primary" children="Login" className="landing-login-button" />
        </div>
      </div>
    </div>
  );
};

export default Landing;
