import "./blockCancelInformation.css";
import moment from "moment";

const BlockCancelInformation = ({ eventProps }) => {
  return (
    <div className="block-information-container">
      <div>
        <span>¿Está seguro de cancelar la cita</span>
        <span>agendado para el día </span>
        <span className="date-text">
          {`${moment(eventProps.event.eventStartDate).format("dddd")} ${moment(
            eventProps.event.eventStartDate
          ).format("Do")} ${moment(eventProps.event.eventStartDate).format(
            "h:mm"
          )} - ${moment(eventProps.event.eventEndDate).format("hh:mm A")}`}
        </span>
        <span>, definitivamente?</span>
      </div>
    </div>
  );
};

export default BlockCancelInformation;
