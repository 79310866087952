import "./reasonSection.css";
import Dropdown from "../../../Dropdown/Dropdown";
import { demoReasons } from "../../../../db/reasons";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const ReasonSection = ({
  className,
  payload,
  handleChangeDropdown,
  errors
}) => {
  const { t } = useTranslation();
  return (
    <div className={className + " reason"}>
      <h3>{getTranslation("Reason for blocking", t)}</h3>
      <span>{getTranslation("Select the reason for blocking", t)}</span>
      <div className="row align-item-end">
        <div className="col-12 col-sm-4 p-0 pe-sm-4">
          <Dropdown
            data={demoReasons.map((item) => ({ ...item, name: getTranslation(item.name, t) }))}
            name="reason"
            handleChange={handleChangeDropdown}
            value={payload.reason}
            label={getTranslation("Reason", t)}
            textField="name"
            valueField="id"
            error={errors.reason}
          />
        </div>
      </div>
    </div>
  );
};

export default ReasonSection;
