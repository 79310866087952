const reasons = [
  {
    name: "Maintenance",
    id: "Maintenance"
  },
  {
    name: "Holiday",
    id: "Holiday"
  }
];
export default reasons;

export const demoReasons = [
  {
    name: "Maintenance",
    id: "Maintenance"
  },
  {
    name: "Holiday",
    id: "Holiday"
  }
];
