import React from "react";
import "./statusSection.css";
import { FormControlLabel, Switch } from "@mui/material";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const StatusSection = ({ payload, handleSwitchChange }) => {
  const { t } = useTranslation();

  return (
    <div className="vehicle-status-box">
      <h4>{getTranslation("Vehicle status", t)}</h4>
      <span>{getTranslation("Select vehicle status", t)}</span>
      <FormControlLabel
        control={
          <Switch
            name="isActive"
            onChange={handleSwitchChange}
            checked={payload?.isActive ?? true}
          />
        }
        label={getTranslation("Active", t)}
      />
    </div>
  );
};

export default StatusSection;
