import {
  IoMdCalendar,
  IoMdArrowDropleft,
  IoMdArrowDropright
} from "react-icons/io";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import getTranslationStartEndDates from "../../../utils/getTranslationStartEndDates";

const CustomToolbar = (props) => {
  const { t } = useTranslation();
  const handleNavigateClick = (action) => props.onNavigate(action);
  const handleViewChange = (view) => props.onViewChange(view);

  return (
    <div className="rbc-custom-header d-flex align-items-center justify-content-between">
      <div className="rbc-action-btn d-flex align-items-center">
        <button onClick={() => handleNavigateClick("TODAY")}>
          {getTranslation("Today", t)}
        </button>
        <button onClick={() => handleNavigateClick("PREV")}>
          <div>
            <IoMdArrowDropleft className="arrow-icon" />
          </div>
        </button>
        <button
          onClick={() => {
            handleNavigateClick("NEXT");
          }}
        >
          <div>
            <IoMdArrowDropright className="arrow-icon" />
          </div>
        </button>
        <div className="d-flex align-items-center">
          <IoMdCalendar className="calendar-icon" />
          <span>{getTranslationStartEndDates(props.date, props.view, t)}</span>
        </div>
      </div>
      <div className="rbc-view-btn">
        <button
          className={props.view === "day" ? "selected" : ""}
          onClick={() => handleViewChange("day")}
        >
          {getTranslation("Day", t)}
        </button>
        <button
          className={props.view === "week" ? "selected" : ""}
          onClick={() => handleViewChange("week")}
        >
          {getTranslation("Week", t)}
        </button>
        <button
          className={props.view === "month" ? "selected" : ""}
          onClick={() => handleViewChange("month")}
        >
          {getTranslation("Month", t)}
        </button>
      </div>
    </div>
  );
};

export default CustomToolbar;
