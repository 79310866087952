export const reportTypes = [
	{
		name: "General report",
		value: 1
	},
	{
		name: "Log report",
		value: 2
	}
];

export const logReportTypes = [
	{
		name: "Showroom status",
		value: 1
	},
	{
		name: "Appointments made",
		value: 2
	},
	{
		name: "Demo blocks made",
		value: 3
	},
	{
		name: "Appointment status changes",
		value: 4
	}
];
