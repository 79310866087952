import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_DEMO_LIST } from "../actions/types";
import { setDemoList, setLoading } from "../actions";
import { getDemoListApi } from "../api/handler";

function* handleGetDemoList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getDemoListApi, args.params);
    if (response.status === 200) {
      yield put(setDemoList(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
  yield put(setLoading(false));
}

export default function* demoSaga() {
  yield all([takeLatest(GET_DEMO_LIST, handleGetDemoList)]);
}
