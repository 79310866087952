import { Container, Dialog } from "@mui/material";
import "./testDriveCalendarModal.css";
import Button from "../../../Button/Button";
import TestDriveCancelInformation from "./TestDriveCancelInformation/TestDriveCancelInformation";
import TestDriveScheduleInformation from "./TestDriveScheduleInformation/TestDriveScheduleInformation";
import moment from "moment";
import TestDriveRescheduleInformation from "./TestDriveRescheduleInformation/TestDriveRescheduleInformation";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import DisclaimerModal from "../../../Modals/DisclaimerModal/DisclaimerModal";
import { formatMonthTranslation } from "../../../../utils/formatDateTime";
import useValidateForm from "../../../../hooks/useValidateForm";

const TestDriveCalendarModal = ({
  filters,
  mode,
  closeModal,
  handleSubmit,
  handleSubmitCancel,
  open,
  eventProps,
  vehicleInfo,
  createPayload,
  setCreatePayload,
  errors,
  updatePayload,
  setUpdatePayload,
  timeList,
  time,
  setTime,
  setTimeList,
  role,
  brandSettings
}) => {
  const { t } = useTranslation();
  const disclaimerType = useRef();

  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [radioBoxValue, setRadioBoxValue] = useState({
    terms: "",
    dataPrivacy: ""
  });

  const { termsAndConditionsText, dataProtectionPolicyText } = brandSettings || {};
  const { isSubmitEnabled } = useValidateForm();

  const isTermsAndConditionsText = brandSettings && termsAndConditionsText;
  const isDataPolicyText = brandSettings && dataProtectionPolicyText;
  const isCancelMode = mode === "cancel";
  const isCreateMode = mode === "create";
  const startDate = new Date(isCreateMode ? eventProps.start : eventProps.eventStartDate);
  const endDate = new Date(isCreateMode ? eventProps.end : eventProps.eventEndDate);

  const toggleDisclaimerModal = (type) => {
    setOpenDisclaimer(!openDisclaimer);
    disclaimerType.current = type;
  };

  const handleRadioBoxChange = (e) => {
    const { value, name } = e.target;
    if (name === "terms") {
      setRadioBoxValue({
        ...radioBoxValue,
        terms: value
      });
    } else {
      setRadioBoxValue({
        ...radioBoxValue,
        dataPrivacy: value
      });
    }
  };

  const acceptConditions = () => isSubmitEnabled(radioBoxValue, isCreateMode ? createPayload : updatePayload, isCreateMode)

  return (
    <Dialog
      className="test-drive-calendar-modal"
      open={open}
      onClose={closeModal}
    >
      <Container
        className={`${isCancelMode ? `cancel-container` : ``
          } d-flex flex-column justify-content-between`}
      >
        {isCancelMode ? (
          <>
            <h2>{getTranslation("Cancel Appointment", t)}</h2>
            <TestDriveCancelInformation eventProps={eventProps} role={role} />
          </>
        ) : (
          <>
            <div className={`header-text ${!isCreateMode ? "mb-2" : ""}`}>
              <h2>{getTranslation(isCreateMode ? "Appointment" : "Re - schedule", t)}</h2>
              <span className="date-text">
                {`${getTranslation(
                  moment(startDate).format("dddd"),
                  t
                )}, ${formatMonthTranslation(startDate, t)} / ${moment(
                  startDate
                ).format("h:mm")} - ${moment(endDate).format(
                  "hh:mm a"
                )}`}
              </span>
            </div>
            <div>
              {isCreateMode ?
                <TestDriveScheduleInformation
                  filters={filters}
                  vehicleInfo={vehicleInfo}
                  payload={createPayload}
                  setPayload={setCreatePayload}
                  errors={errors}
                  toggleDisclaimerModal={toggleDisclaimerModal}
                  radioBoxValue={radioBoxValue}
                  handleRadioBoxChange={handleRadioBoxChange}
                  brandSettings={brandSettings}
                />
                : <TestDriveRescheduleInformation
                  eventProps={eventProps}
                  payload={updatePayload}
                  setPayload={setUpdatePayload}
                  errors={errors}
                  time={time}
                  setTime={setTime}
                  toggleDisclaimerModal={toggleDisclaimerModal}
                  radioBoxValue={radioBoxValue}
                  handleRadioBoxChange={handleRadioBoxChange}
                  timeList={timeList}
                  setTimeList={setTimeList}
                  brandSettings={brandSettings}
                />}
            </div>
          </>
        )}
        <div className="btns order-3">
          <Button onClick={closeModal} type="secondary">
            {getTranslation("Cancel", t)}
          </Button>
          <Button
            onClick={isCancelMode ? handleSubmitCancel : handleSubmit}
            type="primary"
            disabled={isCancelMode ? false : acceptConditions()}
          >
            {getTranslation("Accept", t)}
          </Button>
        </div>
      </Container>
      <DisclaimerModal
        open={openDisclaimer}
        toggleModal={toggleDisclaimerModal}
        disclaimerType={disclaimerType.current}
        textContent={disclaimerType.current === "terms"
          ? isTermsAndConditionsText
            ? brandSettings.termsAndConditionsText
            : ""
          : isDataPolicyText
            ? brandSettings.dataProtectionPolicyText
            : ""}
      />
    </Dialog>
  );
};

export default TestDriveCalendarModal;
