import { useEffect, useState } from "react";
import Dropdown from "../../components/Dropdown/Dropdown";
import { reportTypes } from "../../db/reportTypes";
import GeneralFilter from "./GeneralReport/GeneralFilter/GeneralFilter";
import LogFilter from "./LogReport/LogFilter/LogFilter";
import Button from "../../components/Button/Button";
import GeneralGrid from "./GeneralReport/GeneralGrid/GeneralGrid";
import LogGrid from "./LogReport/LogGrid/LogGrid";
import {
  exportGeneralReport,
  exportLogReport,
  getGeneralReport,
  getLogReport
} from "../../redux-sagas/actions";
import "./reports.css";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Reports = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialGeneralQueryParams = {
    startDate: "",
    endDate: "",
    page: JSON.stringify({ skip: 0, take: 10 }),
    sort: ""
  };
  const initialLogQueryParams = {
    ...initialGeneralQueryParams,
    logType: ""
  };

  const [reportType, setReportType] = useState("");
  const [showGrid, setShowGrid] = useState(false);
  const [generalQueryParams, setGeneralQueryParams] = useState(
    initialGeneralQueryParams
  );
  const [logQueryParams, setLogQueryParams] = useState(initialLogQueryParams);

  useEffect(() => {
    setShowGrid(reportType === 1);
    if (reportType === 1) setGeneralQueryParams(initialGeneralQueryParams);
    else setLogQueryParams(initialLogQueryParams);
  }, [reportType]);

  useEffect(() => {
    if (reportType === 1) dispatch(getGeneralReport(buildGeneralQuery()));
  }, [generalQueryParams]);

  useEffect(() => {
    // for fetching log data
    if (reportType === 2 && logQueryParams.logType)
      dispatch(getLogReport(buildLogQuery()));
  }, [logQueryParams]);

  useEffect(() => {
    // for showing/hiding grid
    const { logType } = logQueryParams;

    if (!showGrid && logType) setShowGrid(true);
    else if (!logType) setShowGrid(false);
  }, [logQueryParams]);

  const buildGeneralQuery = () => {
    const { startDate, endDate, sort, page } = generalQueryParams;

    return `?page=${page}&sort=${sort}&startDate=${startDate}&endDate=${endDate}`;
  };

  const buildLogQuery = () => {
    const { startDate, endDate, logType, page, sort } = logQueryParams;

    return `/${logType}?page=${page}&sort=${sort}&startDate=${startDate}&endDate=${endDate}`;
  };

  const handleChangeReportType = (e) => setReportType(e.target.value);

  const handleExportReport = () => {
    if (reportType === 1) {
      const { startDate, endDate } = generalQueryParams;
      const generalExportParams = `?startDate=${startDate}&endDate=${endDate}`;
      dispatch(exportGeneralReport(generalExportParams));
    } else {
      const { startDate, endDate, logType } = logQueryParams;
      const logExportParams = `/${logType}?startDate=${startDate}&endDate=${endDate}`;
      dispatch(exportLogReport(logExportParams));
    }
  };

  return (
    <div className="cont reports">
      <div className="filters">
        <Dropdown
          value={reportType}
          data={reportTypes.map(item => ({...item, name: getTranslation(item.name, t)}))}
          label={"*" + getTranslation("Reports", t)}
          name="reportType"
          handleChange={handleChangeReportType}
          valueField="value"
          textField="name"
        />
        {reportType === 1 && (
          <GeneralFilter
            generalQueryParams={generalQueryParams}
            setGeneralQueryParams={setGeneralQueryParams}
          />
        )}
        {reportType === 2 && (
          <LogFilter
            logQueryParams={logQueryParams}
            setLogQueryParams={setLogQueryParams}
          />
        )}
      </div>
      {showGrid && (
        <div className="grid">
          <Button onClick={handleExportReport} type="export">
            {getTranslation("Download", t)}
          </Button>
          {reportType === 1 ? (
            <GeneralGrid
              generalQueryParams={generalQueryParams}
              setGeneralQueryParams={setGeneralQueryParams}
            />
          ) : (
            <LogGrid
              logQueryParams={logQueryParams}
              setLogQueryParams={setLogQueryParams}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Reports;
