import React from "react";
import "./invalidLogin.css";

const InvalidLogin = () => {
  return (
    <div className="invalid-main">
      <div className="invalid-container">
        <h2>Lo sentimos, no pudimos validar tu identidad</h2>
        <h3>Por favor solicita permiso al correo xxxxx@xxxxxxxx.com</h3>
      </div>
    </div>
  );
};

export default InvalidLogin;
