import { Nav } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import "./sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { setAuth, setUserSettings } from "../../redux-sagas/actions";
import { LuLogIn } from "react-icons/lu";
import inchcapeLogo from "../../assets/images/inchcape-logo.svg";
import menuItems from "../../db/menuItems";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import Cookie from "../../services/Cookies";
import { useEffect } from "react";

const Sidebar = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const userMenu = useSelector((state) => state.menu);

  const handleLogout = () => {
    Cookie.remove("tdb_token");
    Cookie.remove("tdb_rftoken");
    dispatch(setAuth(false));
    dispatch(setUserSettings(null));
  };


  return (
    <div className="sidebar">
      <img className="inch-logo" src={inchcapeLogo} alt="inchcape logo" />
      <h3>Test Drive</h3>
      <Nav>
        <ul>
          {menuItems.map((nav, idx) => { 
            if (userMenu.includes(nav.name)) {
              return (
                <Nav.Link
                  key={idx}
                  onClick={() => Navigate(nav.path)}
                  active={location.pathname === nav.path}
                >
                  {nav.icon}
                  <span>{getTranslation(nav.title, t)}</span>
                </Nav.Link>);
            }
            return null;
          })}
        </ul>
        <Nav.Link onClick={handleLogout}>
          <LuLogIn />
          <span>{getTranslation("logout", t)}</span>
        </Nav.Link>
      </Nav>
    </div>
  );
};

export default Sidebar;
