import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  GET_DOCUMENT_TYPE_LIST,
  GET_COMMON_SHOWROOM_LIST,
  GET_VIN_LIST
} from "../actions/types";
import {
  getDocumentTypeListApi,
  getCommonShowroomListApi,
  getVinListApi
} from "../api/handler";
import {
  setDocumentTypeList,
  setCommonShowroomList,
  setVinList
} from "../actions";

function* handleLoadDocumentTypeList() {
  try {
    const response = yield call(getDocumentTypeListApi);
    if (response.status === 200) {
      yield put(setDocumentTypeList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleLoadShowroomList({ params }) {
  try {
    const response = yield call(getCommonShowroomListApi, params);
    if (response.status === 200) {
      yield put(setCommonShowroomList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

function* handleLoadVinList(args) {
  try {
    const response = yield call(getVinListApi, args.params);
    if (response.status === 200) {
      yield put(setVinList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* commonDataSaga() {
  yield all([
    takeLatest(GET_DOCUMENT_TYPE_LIST, handleLoadDocumentTypeList),
    takeLatest(GET_COMMON_SHOWROOM_LIST, handleLoadShowroomList),
    takeLatest(GET_VIN_LIST, handleLoadVinList)
  ]);
}
