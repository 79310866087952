import { useState } from "react";

const useGridFunctionalities = () => {
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10
	});
	const [sort, setSort] = useState("");
	const [filters, setFilters] = useState({});
	const areFiltersApplied = Object.keys(filters).length;

	const handlePaginationChange = (newModel) => setPaginationModel(newModel);

	const handleSortChange = (props) => setSort(props[0] ?? "");

	const getPageSkip = () => paginationModel.page * paginationModel.pageSize;

	const handleFiltersChange = (newFilters) => setFilters(newFilters);

	const resetPageToZero = () => setPaginationModel({ ...paginationModel, page: 0 });

	const handleClearFilters = (filterValue) => {
		if (areFiltersApplied) {
			handleFiltersChange(filterValue);
			resetPageToZero();
		}
	};

	const handleSearchFilters = () => {
		if (areFiltersApplied) resetPageToZero();
	};

	return {
		paginationModel,
		sort,
		filters,
		handleFiltersChange,
		handlePaginationChange,
		handleSortChange,
		getPageSkip,
		handleClearFilters,
		handleSearchFilters,
		resetPageToZero
	};
};

export default useGridFunctionalities;
