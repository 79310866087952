import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const ProtectedRoute = ({ children }) => {
  const { i18n } = useTranslation();
  const role = useSelector((state) => state.userInfo?.accessLevel);
  const lang = useSelector((state) => state.userInfo?.defaultLanguage) ?? "es";
  let location = useLocation();
  let shouldAccess = false,
    redirect;

  const checkAccess = (path) => {
    if (location.pathname === path) {
      shouldAccess = true;
    } else {
      redirect = path;
    }
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  switch (role) {
    case 5:
      // TD Admin
      if (location.pathname === "/blocks" || location.pathname === "/calendar")
        shouldAccess = true;
      else checkAccess("/showrooms");
      break;
    case 4:
      // Fleet Coordinator
      if (location.pathname === "/blocks") shouldAccess = true;
      else checkAccess("/demos");
      break;
    case 3:
      // Sales manager
      if (location.pathname === "/calendar") shouldAccess = true;
      checkAccess("/blocks");
      break;
    case 1:
    case 2:
      // Contact center - 2
      // Sales rep - 1
      checkAccess("/calendar");
      break;
    default:
      break;
  }

  if (!shouldAccess) {
    return <Navigate to={redirect} />;
  }

  return children;
};

export default ProtectedRoute;
