import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import "./content.css";
import DeleteModal from "../../components/Modals/DeleteModal/DeleteModal";
import ReassignModal from "../../components/Modals/ReassignModal/ReassignModal";
import Notification from "../../components/Notification/Notification";

const Content = () => {
  return (
    <div className="main-cont">
      <Sidebar />
      <div className="right-cont">
        <Header />
        {<Notification />}
        <Outlet />
      </div>
      {<DeleteModal />}
      {<ReassignModal />}
    </div>
  );
};

export default Content;
