import "./demoGrid.css";
import { DataGrid } from "@mui/x-data-grid";
import { useDispatch, useSelector } from "react-redux";
import {
	getDemoList,
	toggleDeleteModal,
	toggleNotification,
	toggleReassignModal
} from "../../../redux-sagas/actions";
import { fetchDemoColumns } from "../../../utils/fetchColumns";
import fetchClient from "../../../services/fetch";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const DemoGrid = ({
	setMode,
	toggleEditModal,
	buildQuery,
	setSelected,
	paginationModel,
	handlePaginationChange,
	handleSortChange
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isLoading = useSelector((state) => state.isLoading);
	const demoListInfo = useSelector((state) => state.demoList);

	const handleEditClick = (params) => {
		setMode("edit");
		setSelected(params);
		toggleEditModal();
	};

	const openReassignModal = () =>
		dispatch(
			toggleReassignModal({
				isOpen: true,
				title: getTranslation("Reassign Demo", t),
				content: t("demo_reassign_events")
			})
		);

	const openDeleteModal = (params) =>
		dispatch(
			toggleDeleteModal({
				isOpen: true,
				title: getTranslation("Remove Demo", t),
				content1: t("sure_to_delete_demo"),
				closeCallback: () => handleDelete(params)
			})
		);

	const handleDelete = (params) =>
		fetchClient()
			.delete(`/vehicles/${params.vehicleId}`)
			.then(() => {
				dispatch(getDemoList(buildQuery()));
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: getTranslation("The demo for the VIN", t),
						highlight1: params.vin,
						content2: `${getTranslation("has been", t)} ${t("deleted_la")} ${getTranslation(
							"successfully",
							t
						)}.`
					})
				);
			})
			.catch((err) => {
				console.log(err);
				openReassignModal();
			});

	const handleDeleteClick = (params) => {
		if (!params.deletable) openReassignModal();
		else openDeleteModal(params);
	};

	return (
		<DataGrid
			getRowId={(row) => row.vehicleId}
			getRowHeight={() => "auto"}
			onSortModelChange={(newSortModel) => handleSortChange(newSortModel)}
			columns={fetchDemoColumns(handleEditClick, handleDeleteClick).map((item) => ({
				...item,
				headerName: getTranslation(item.headerName, t)
			}))}
			rows={demoListInfo.data}
			rowCount={demoListInfo.totalRecords || 0}
			paginationModel={paginationModel}
			loading={isLoading}
			pageSizeOptions={[5, 10, 15, 20]}
			slotProps={{
				pagination: {
					labelRowsPerPage: getTranslation("Rows per page:", t)
				}
			}}
			disableColumnMenu
			paginationMode='server'
			onPaginationModelChange={(paginationModel) => handlePaginationChange(paginationModel)}
			disableRowSelectionOnClick
		/>
	);
};

export default DemoGrid;
