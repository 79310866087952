import { Dialog } from "@mui/material";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { toggleReassignModal } from "../../../redux-sagas/actions";
import "./reassignModal.css";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const ReassignModal = () => {
  const { isOpen, title, content } = useSelector(
    (state) => state.reassignModal
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeModal = () =>
    dispatch(
      toggleReassignModal({
        isOpen: false,
        title,
        content
      })
    );

  return (
    <Dialog
      className="delete-reassign-modal"
      open={isOpen}
      onClose={closeModal}
    >
      <h2>{title}</h2>
      <span className="content">{content}</span>
      <Button onClick={closeModal} type="primary">
        {getTranslation("Accept", t)}
      </Button>
    </Dialog>
  );
};

export default ReassignModal;
