import React from "react";
import { FormControl, Select, MenuItem, InputLabel } from "@mui/material";
import "./dropdown.css";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Dropdown = ({
	data,
	value,
	handleChange,
	onOpen,
	disabled,
	name,
	valueField,
	textField,
	error,
	label,
	className,
	isRequired = false
}) => {
	const { t } = useTranslation();
	return (
		<div className={`dropdown-input ${className} ${error ? "error" : ""}`}>
			<FormControl
				variant='standard'
				error={!value ? error : false}
				required={isRequired}
			>
				<InputLabel>{label}</InputLabel>
				<Select
					onOpen={onOpen}
					disabled={disabled | false}
					value={value ?? ""}
					onChange={handleChange}
					name={name}
				>
					{data?.map((datum, idx) => (
						<MenuItem
							key={datum.valueField ?? idx}
							value={datum[valueField]}
						>
							{datum[textField]}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{error && <span className='error'>{getTranslation("Fill in this field correctly.", t)}</span>}
		</div>
	);
};

export default Dropdown;
