import {
  SET_AUTH,
  SET_DEMO_LIST,
  SET_BRAND_LIST,
  SET_CALENDAR_EVENTS,
  SET_CITIES_LIST,
  SET_FUEL_TYPE_LIST,
  SET_LOADING,
  SET_MODEL_LIST,
  SET_SHOWROOM_LIST,
  SET_USER_INFO,
  SET_USER_MENU,
  SET_USER_SETTINGS,
  SET_VARIANT_LIST,
  TOGGLE_DELETE_MODAL,
  TOGGLE_NOTIFICATION,
  TOGGLE_REASSIGN_MODAL,
  SET_BLOCK_DEMO_LIST,
  SET_BLOCKED_SHOWROOMS_LIST,
  SET_DOCUMENT_TYPE_LIST,
  SET_COMMON_SHOWROOM_LIST,
  SET_GENERAL_REPORT,
  SET_LOG_REPORT,
  SET_VIN_LIST
} from "../actions/types";

const initialState = {
  isAuth: false,
  isLoading: false,
  userSettings: null,
  userInfo: { accessLevel: 0 },
  menu: [],
  deleteModal: {
    isOpen: false,
    title: "",
    content1: "",
    content2: "",
    highlight: ""
  },
  reassignModal: {
    isOpen: false,
    title: "",
    content: ""
  },
  notification: {
    isOpen: false,
    type: "",
    content1: "",
    content2: "",
    highlight1: "",
    highlight2: ""
  },
  showroomList: {
    totalRecords: 0,
    totalPages: 0,
    data: []
  },
  demoList: {
    totalRecords: 0,
    totalPages: 0,
    data: []
  },
  blockDemoList: {
    totalRecords: 0,
    totalPages: 0,
    data: []
  },
  brandList: { totalRecords: 0, totalPages: 0, data: [] },
  modelList: { totalRecords: 0, totalPages: 0, data: [] },
  variantList: { totalRecords: 0, totalPages: 0, data: [] },
  commonShowroomList: { totalRecords: 0, totalPages: 0, data: [] },
  fuelTypeList: { totalRecords: 0, totalPages: 0, data: [] },
  citiesList: { totalRecords: 0, totalPages: 0, data: [] },
  documentTypeList: { totalRecords: 0, totalPages: 0, data: [] },
  vinList: { totalRecords: 0, totalPages: 0, data: [] },
  calendarEvents: [],
  blockedShowroomsList: {
    totalRecords: 0,
    totalPages: 0,
    data: []
  },
  generalReport: {
    totalRecords: 0,
    totalPages: 0,
    data: []
  },
  logReport: {
    totalRecords: 0,
    totalPages: 0,
    data: []
  }
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH:
      return { ...state, isAuth: action.isAuth };
    case SET_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_USER_SETTINGS:
      return { ...state, userSettings: { role: 4 } };
    case TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: action.deleteModalValues };
    case TOGGLE_REASSIGN_MODAL:
      return { ...state, reassignModal: action.reassignModalValues };
    case TOGGLE_NOTIFICATION:
      return { ...state, notification: action.notificationValues };
    case SET_SHOWROOM_LIST:
      return { ...state, showroomList: action.showroomList };
    case SET_BRAND_LIST:
      return { ...state, brandList: action.brandList };
    case SET_MODEL_LIST:
      return { ...state, modelList: action.modelList };
    case SET_VARIANT_LIST:
      return { ...state, variantList: action.variantList };
    case SET_VIN_LIST:
      return { ...state, vinList: action.vinList };
    case SET_COMMON_SHOWROOM_LIST:
      return { ...state, commonShowroomList: action.commonShowroomList };
    case SET_FUEL_TYPE_LIST:
      return { ...state, fuelTypeList: action.fuelTypeList };
    case SET_DOCUMENT_TYPE_LIST:
      return { ...state, documentTypeList: action.documentTypeList };
    case SET_CITIES_LIST:
      return { ...state, citiesList: action.citiesList };
    case SET_CALENDAR_EVENTS:
      return { ...state, calendarEvents: action.calendarEvents };
    case SET_USER_INFO:
      return { ...state, userInfo: action.userInfo };
    case SET_USER_MENU:
      return { ...state, menu: action.userMenu };
    case SET_BLOCKED_SHOWROOMS_LIST:
      return { ...state, blockedShowroomsList: action.blockedShowroomsList };
    case SET_DEMO_LIST:
      return { ...state, demoList: action.demoList };
    case SET_BLOCK_DEMO_LIST:
      return { ...state, blockDemoList: action.blockDemoList };
    case SET_GENERAL_REPORT:
      return { ...state, generalReport: action.generalReport };
    case SET_LOG_REPORT:
      return { ...state, logReport: action.logReport };
    default:
      return state;
  }
};

export default reducer;
