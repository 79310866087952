import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_MODEL_LIST } from "../actions/types";
import { getCommonModelListApi } from "../api/handler";
import { setModelList } from "../actions";

function* handleLoadModelList(args) {
  try {
    const response = yield call(getCommonModelListApi, args.params);
    if (response.status === 200) {
      yield put(setModelList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* modelSaga() {
  yield all([takeLatest(GET_MODEL_LIST, handleLoadModelList)]);
}
