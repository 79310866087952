import "./customEvent.css";
import { useSelector } from "react-redux";
import { formatDateForEvents } from "../../../utils/formatDateTime";

const CustomEvent = (props) => {
  const currentRole = useSelector((state) => state.userInfo);

  const eventTimeDisplay =
    currentRole.accessLevel === 2 || currentRole.accessLevel === 1;

  const getEventClassName = () => {
    switch (props.event.eventType) {
      case 0:
        return "block-event";
      case 2:
        return "show-event";
      case 3:
        return "no-show-event";
      default:
        return "test-drive-event";
    }
  }

  return (
    <div
      id={props.event.eventId}
      className={`${getEventClassName()} custom-event`}
    >
      <div
        className={
          eventTimeDisplay && props.event.eventType === 0
            ? "d-none time"
            : "time"
        }
      >
        {formatDateForEvents(props.event.eventStartDate, props.event.eventEndDate)}
      </div>
      <div className="title">{props.title}</div>
      {props.event.eventType !== 0 && props.event.eventData && (
        <div className="brand">{props.event.eventData.brandName}</div>
      )}
    </div>
  );
};

export default CustomEvent;
