import { GridHeaderCheckbox } from "@mui/x-data-grid";
import "./gridSelectHeader.css";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const GridSelectHeader = (params) => {
  const { t } = useTranslation();
  return (
    <div className="select-header">
      <span>{getTranslation("Select all", t)}</span>
      <GridHeaderCheckbox {...params} />
    </div>
  );
};

export default GridSelectHeader;
