const filterOptions = [
  {
    name: "VIN",
    value: "vin"
  },
  {
    name: "Brand",
    value: "brandName"
  },
  {
    name: "Model",
    value: "modelName"
  },
  {
    name: "Variant",
    value: "variantName"
  },
  {
    name: "City",
    value: "cityName"
  }
];

export default filterOptions;
