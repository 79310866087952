import { all } from "redux-saga/effects";
import showroomSaga from "./showroom";
import brandSaga from "./brand";
import modelSaga from "./model";
import variantSaga from "./variant";
import fuelTypeSaga from "./fuelType";
import calendarSaga from "./calendar";
import bookingSaga from "./booking";
import userSaga from "./user";
import citiesSaga from "./cities";
import blockSaga from "./block";
import blockedShowroomsSaga from "./blockedShowroom";
import demoSaga from "./demo";
import blockDemo from "./blockDemo";
import commonDataSaga from "./commonData";
import reportsSaga from "./reports";
import exportSaga from "./export";

const rootSagas = function* rootSagas() {
  yield all([
    showroomSaga(),
    userSaga(),
    demoSaga(),
    brandSaga(),
    modelSaga(),
    variantSaga(),
    fuelTypeSaga(),
    calendarSaga(),
    bookingSaga(),
    citiesSaga(),
    blockSaga(),
    blockDemo(),
    blockedShowroomsSaga(),
    commonDataSaga(),
    reportsSaga(),
    exportSaga()
  ]);
};

export default rootSagas;
