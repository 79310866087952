import { LuWrench } from "react-icons/lu";
import { CgCloseO } from "react-icons/cg";
import { TbReportAnalytics } from "react-icons/tb";
import { IoCalendarOutline } from "react-icons/io5";

const menuItems = [
  {
    name: "Showroom Management",
    title: "Showroom Management",
    icon: <LuWrench />,
    path: "/showrooms"
  },
  {
    name: "Demo Management",
    title: "Demo Management",
    icon: <LuWrench />,
    path: "/demos"
  },
  {
    name: "Showroom Block Management",
    title: "Block Management",
    icon: <CgCloseO />,
    path: "/blocks"
  },
  {
    name: "Demo Block Management",
    title: "Block Management",
    icon: <CgCloseO />,
    path: "/blocks"
  },
  {
    name: "Block Calendar",
    title: "Calendar",
    icon: <IoCalendarOutline />,
    path: "/calendar"
  },
  {
    name: "Test Drive Calendar",
    title: "Calendar",
    icon: <IoCalendarOutline />,
    path: "/calendar"
  },
  {
    name: "Reports",
    title: "Reports",
    icon: <TbReportAnalytics />,
    path: "/reports"
  }
];

export default menuItems;
