import Multiselect from "../../../Multiselect/Multiselect";
import TextInput from "../../../TextInput/TextInput";
import Dropdown from "../../../Dropdown/Dropdown";
import "./informationSection.css";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const InformationSection = ({
  payload,
  errors,
  handleTextSelectChange,
  handleMultiselectChange,
  dropdownData,
  getDropdownData
}) => {
  const { t } = useTranslation();
  const handleOpenDropdown = (name) => getDropdownData(name);

  return (
    <div className="infos">
      <h3>{getTranslation("Showroom Information", t)}</h3>
      <div className="row gx-5">
        <TextInput
          handleChange={handleTextSelectChange}
          value={payload.showroomName}
          className="col-6 ps-0"
          label={getTranslation("Name", t)}
          error={errors.showroomName}
          name="showroomName"
        />
        <Dropdown
          handleChange={handleTextSelectChange}
          onOpen={() => handleOpenDropdown("cities")}
          data={dropdownData.cities}
          value={payload.cityId}
          error={errors.cityId}
          textField="name"
          valueField="id"
          className="col-6 pe-0"
          label={getTranslation("City", t)}
          name="cityId"
        />
        <TextInput
          handleChange={handleTextSelectChange}
          value={payload.address}
          error={errors.address}
          className="col-6 ps-0"
          label={getTranslation("Address", t)}
          name="address"
        />
        <Multiselect
          className="multiselect col-6 pe-0"
          data={dropdownData.brands}
          value={payload.brands}
          error={errors.brands}
          name="brands"
          label={getTranslation("Test Drive Brands", t)}
          onOpen={() => handleOpenDropdown("brands")}
          subtext={getTranslation("Multiple selection field", t)}
          handleChange={handleMultiselectChange}
        />
      </div>
    </div>
  );
};

export default InformationSection;
