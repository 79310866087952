import "./vehicleSection.css";
import Dropdown from "../../../Dropdown/Dropdown";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const VehicleSection = ({
  className,
  mode,
  selectedMultiple,
  handleChangeDropdown,
  dropdownData,
  dropdownValue,
  getDropdownData,
  errors
}) => {
  const { t } = useTranslation();
  const handleOpenDropdown = (name) => getDropdownData(name);

  return (
    <div className={className + " vehicle-info"}>
      <h3>
        {mode === "multiple"
          ? getTranslation("Vehicle information multiple", t)
          : getTranslation("Vehicle information", t)}
      </h3>
      <span className={mode !== "create" && "d-none"}>
        {getTranslation("Select vehicle data", t)}
      </span>
      {mode === "create" ? (
        <>
          <div className="vehicle-dropdown row align-item-end">
            <div className="col-12 col-sm-4 p-0 pe-sm-4">
              <Dropdown
                label={getTranslation("Brand", t)}
                data={dropdownData.brands}
                handleChange={handleChangeDropdown}
                onOpen={() => handleOpenDropdown("brands")}
                textField="name"
                valueField="id"
                value={dropdownValue.brandId}
                name="brandId"
                error={errors.vehicleId}
              />
            </div>
            <div className="col-12 col-sm-4 p-0 px-sm-4">
              <Dropdown
                label={getTranslation("Model", t)}
                data={dropdownData.models}
                handleChange={handleChangeDropdown}
                onOpen={() => handleOpenDropdown("models")}
                textField="name"
                valueField="id"
                value={dropdownValue.modelId}
                name="modelId"
                error={errors.vehicleId}
              />
            </div>
            <div className="col-12 col-sm-4 p-0 ps-sm-4">
              <Dropdown
                label="VIN"
                data={dropdownData.vins}
                handleChange={handleChangeDropdown}
                onOpen={() => handleOpenDropdown("vins")}
                textField="name"
                valueField="id"
                value={dropdownValue.vehicleId}
                name="vehicleId"
                error={errors.vehicleId}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="vehicle-dropdown row align-item-end">
          {selectedMultiple.map((selected) => (
            <>
              <div
                className="col-12 col-sm-4 p-0 pe-sm-4 mb-3"
                key={selected.brandId}
              >
                <p>{getTranslation("Brand", t)}</p>
                <span>{selected.brandName}</span>
              </div>
              <div
                className="col-12 col-sm-4 p-0 px-sm-4 mb-3"
                key={selected.modeldId}
              >
                <p>{getTranslation("Model", t)}</p>
                <span>{selected.modelName}</span>
              </div>
              <div
                className="col-12 col-sm-4 p-0 ps-sm-4 mb-3"
                key={selected.vin}
              >
                <p>VIN</p>
                <span>{selected.vin}</span>
              </div>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

export default VehicleSection;
