import { useEffect } from "react";
import AppRouter from "./routers/Routers";
import { useDispatch } from "react-redux";
import { getUserInfo, getUserMenu, setAuth, setUserSettings } from "./redux-sagas/actions";
import { useLocation, useNavigate } from "react-router-dom";
import Cookie from "./services/Cookies";

function App() {
  const dispatch = useDispatch();
  let location = useLocation();
  const Navigate = useNavigate();

  useEffect(() => {
    if (Cookie.get("tdb_token")) {
      dispatch(getUserInfo());
      dispatch(getUserMenu());
      dispatch(setAuth(true));
      setTimeout(() => {
        dispatch(setUserSettings());
      }, 500);
      Navigate(location.pathname);
    }
  }, []);

  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
