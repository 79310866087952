import moment from "moment";
import { getTranslation } from "./getTranslation";
import i18next from "i18next";

const getTranslationStartEndDates = (date, view, t) => {
  let start, end;
  const lng = i18next.language;

  if (lng === "es") {
    if (view === "day") {
      start = moment(date).startOf("day");
      end = moment(date).endOf("day");

      return `${getTranslation(moment(start).format("dddd"), t)}, ${moment(
        start
      ).format("DD")} de ${getTranslation(
        moment(start).format("MMMM"),
        t
      )} de ${moment(start).format("YYYY")}`;
    } else if (view === "week") {
      start = moment(date).startOf("week");
      end = moment(date).endOf("week");

      return `${getTranslation(moment(start).format("dddd"), t)}, ${moment(
        start
      ).format("DD")} de ${getTranslation(
        moment(start).format("MMMM"),
        t
      )} de ${moment(start).format("YYYY")} - ${getTranslation(
        moment(end).format("dddd"),
        t
      )}, ${moment(end).format("DD")} de ${getTranslation(
        moment(end).format("MMMM"),
        t
      )} de ${moment(end).format("YYYY")}`;
    } else if (view === "month") {
      return `${getTranslation(moment(date).format("MMMM"), t)} de ${moment(
        date
      ).format("YYYY")}`;
    }
  } else {
    if (view === "day") {
      start = moment(date).startOf("day");
      end = moment(date).endOf("day");

      return `${moment(start).format("dddd")}, ${moment(start).format(
        "MMMM DD YYYY"
      )}`;
    } else if (view === "week") {
      start = moment(date).startOf("week");
      end = moment(date).endOf("week");

      return `${moment(start).format("dddd, MMMM DD YYYY")} - ${moment(
        end
      ).format("dddd, MMMM DD YYYY")} `;
    } else if (view === "month") {
      start = moment(date).startOf("month").startOf("week");
      end = moment(date).endOf("month").endOf("week");

      return `${moment(date).format("MMMM YYYY")}`;
    }
  }
};

export default getTranslationStartEndDates;
