//import React, { useEffect } from "react";
import "./blockDemoFilter.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Button from "../../../../components/Button/Button";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import TextInput from "../../../../components/TextInput/TextInput";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";

const BlockDemoFilter = ({
  filters,
  handleFiltersChange,
  handleSearchFilters,
  handleClearFilters,
  resetPageToZero
}) => {
  const { t, i18n } = useTranslation();

  const handleChange = (e, newValue = undefined) => {
    handleFiltersChange({
      ...filters,
      [e.target.name]: e.target.name === "vin" ? e.target.value : newValue
    });
    resetPageToZero();
  };

  return (
    <div className="filters order-1">
      <span>{getTranslation("Select filter", t)}</span>
      <div className="row">
        <TextInput
          className="col-xl-3 ps-0 pe-sm-0 pe-xl-5 align-self-end"
          label="VIN"
          name="vin"
          value={filters.vin}
          handleChange={handleChange}
        />
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <DatePicker
            className="calendar-input col-xl-3 ps-0 pe-sm-0 pe-xl-5 align-self-end"
            label={getTranslation("Start Date", t)}
            value={filters.startDate ?? null}
            onChange={(newValue) => handleChange({ target: { name: "startDate" } }, newValue)}
          />
          <DatePicker
            className="calendar-input col-xl-3 ps-0 pe-sm-0 pe-xl-5 mb-4 mb-xl-0 align-self-end"
            label={getTranslation("End Date", t)}
            value={filters.endDate ?? null}
            onChange={(newValue) => handleChange({ target: { name: "endDate" } }, newValue)}
          />
        </LocalizationProvider>
        <div className="btns col-xl-3 px-0 d-flex flex-wrap flex-xxl-nowrap justify-content-xl-between">
          <Button
            type="secondary me-2 me-xl-0"
            onClick={() => handleClearFilters({})}
          >
            {getTranslation("Clean", t)}
          </Button>
          <Button
            type="primary"
            onClick={handleSearchFilters}
          >
            {getTranslation("Search", t)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BlockDemoFilter;
