import { useEffect, useRef, useState } from "react";
import Button from "../../components/Button/Button";
import ShowroomFilters from "./ShowroomFilters/ShowroomFilters";
import ShowroomGrid from "./ShowroomGrid/ShowroomGrid";
import CreateShowroomModal from "../../components/Modals/CreateShowroomModal/CreateShowroomModal";
import "./manageShowrooms.css";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import useGridFunctionalities from "../../hooks/useGridFunctionalities";
import { useDispatch } from "react-redux";
import { getShowroomList } from "../../redux-sagas/actions";

const ManageShowrooms = () => {
	const { t } = useTranslation();
	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [selected, setSelected] = useState(null);
	const [mode, setMode] = useState("create");
	const {
		paginationModel,
		sort,
		handlePaginationChange,
		handleSortChange,
		getPageSkip,
		filters,
		handleFiltersChange,
		handleClearFilters,
		handleSearchFilters,
		resetPageToZero
	} = useGridFunctionalities();

	const isInitial = useRef(false);
	const dispatch = useDispatch();
	const toggleModal = () => setIsCreateModalOpen(!isCreateModalOpen);
	const openCreateModal = () => {
		setMode("create");
		toggleModal();
	};

	const buildQuery = () => {
		let filterQuery = "";
		const filterLength = Object.keys(filters).length;
		let i = 1;
		for (const key in filters) {
			filterQuery += JSON.stringify({
				field: key,
				condition: "eq",
				value: filters[key]
			}).concat(filterLength && i !== filterLength ? "," : "");
			i++;
		}

		return `?filters=[${filterQuery}]&sort=${JSON.stringify(sort)}&page=${JSON.stringify({
			skip: getPageSkip(),
			take: paginationModel.pageSize
		})}`;
	};

	useEffect(() => {
		const getData = () => dispatch(getShowroomList(buildQuery()));

		if (!isInitial.current) {
			const getDataWithBuffer = setTimeout(getData, 200);
			return () => clearTimeout(getDataWithBuffer);
		} else {
			isInitial.current = false;
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, sort, paginationModel]);

	return (
		<div className='cont showrooms'>
			<ShowroomFilters
				filters={filters}
				handleFiltersChange={handleFiltersChange}
				handleClearFilters={handleClearFilters}
				handleSearchFilters={handleSearchFilters}
				resetPageToZero={resetPageToZero}
			/>
			<div className='grid'>
				<Button
					onClick={openCreateModal}
					type='create'
				>
					{getTranslation("Create Showroom", t)}
				</Button>
				<ShowroomGrid
					setSelected={setSelected}
					buildQuery={buildQuery}
					setMode={setMode}
					paginationModel={paginationModel}
					handlePaginationChange={handlePaginationChange}
					handleSortChange={handleSortChange}
					toggleModal={toggleModal}
				/>
			</div>
			{isCreateModalOpen && (
				<CreateShowroomModal
					selected={selected}
					toggleModal={toggleModal}
					buildQuery={buildQuery}
					mode={mode}
					open={isCreateModalOpen}
				/>
			)}
		</div>
	);
};

export default ManageShowrooms;
