import Dropdown from "../../../Dropdown/Dropdown";
import { useState } from "react";
import fetchClient from "../../../../services/fetch";
import "./showroomInformationSection.css";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const ShowroomInformationSection = ({
  className,
  errors,
  mode,
  handleChangeDropdown,
  selectedMultiple,
  payload
}) => {
  const { t } = useTranslation();
  const [showroomData, setShowroomData] = useState([]);

  const handleOpenDropdown = () =>
    fetchClient()
      .get("/commondata/showrooms")
      .then((res) => setShowroomData(res.data.data))
      .catch((err) => console.log(err));

  return (
    <div className={className + " showroom-info"}>
      <h3>{getTranslation("Showroom Information", t)}</h3>
      {mode === "create" ? (
        <>
          <span>{getTranslation("Select showroom", t)}</span>
          <Dropdown
            error={errors.showroomIds}
            name="showroomIds"
            handleChange={handleChangeDropdown}
            onOpen={handleOpenDropdown}
            data={showroomData}
            textField="name"
            valueField="id"
            value={payload.showroomIds[0]}
            label="Showroom"
          />
        </>
      ) : (
        <div className="showrooms">
          {selectedMultiple.map((selected) => (
            <div key={selected.showroomId}>
              <p>Showroom</p>
              <span>{selected.showroomName}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ShowroomInformationSection;
