export const SET_AUTH = "SET_AUTH";
export const SET_LOADING = "SET_LOADING";
export const SET_USER_SETTINGS = "SET_USER_SETTINGS";
export const TOGGLE_DELETE_MODAL = "TOGGLE_DELETE_MODAL";
export const TOGGLE_REASSIGN_MODAL = "TOGGLE_REASSIGN_MODAL";
export const TOGGLE_NOTIFICATION = "TOGGLE_NOTIFICATION";

export const GET_BRAND_LIST = "GET_BRAND_LIST";
export const SET_BRAND_LIST = "SET_BRAND_LIST";

export const GET_SHOWROOM_LIST = "GET_SHOWROOM_LIST";
export const SET_SHOWROOM_LIST = "SET_SHOWROOM_LIST";

export const GET_DEMO_LIST = "GET_DEMO_LIST";
export const SET_DEMO_LIST = "SET_DEMO_LIST";

export const GET_BLOCK_DEMO_LIST = "GET_BLOCK_DEMO_LIST";
export const SET_BLOCK_DEMO_LIST = "SET_BLOCK_DEMO_LIST";

export const GET_MODEL_LIST = "GET_MODEL_LIST";
export const SET_MODEL_LIST = "SET_MODEL_LIST";

export const GET_VARIANT_LIST = "GET_VARIANT_LIST";
export const SET_VARIANT_LIST = "SET_VARIANT_LIST";

export const GET_COMMON_SHOWROOM_LIST = "GET_COMMON_SHOWROOM_LIST";
export const SET_COMMON_SHOWROOM_LIST = "SET_COMMON_SHOWROOM_LIST";

export const GET_FUEL_TYPE_LIST = "GET_FUEL_TYPE_LIST";
export const SET_FUEL_TYPE_LIST = "SET_FUEL_TYPE_LIST";

export const GET_DOCUMENT_TYPE_LIST = "GET_DOCUMENT_TYPE_LIST";
export const SET_DOCUMENT_TYPE_LIST = "SET_DOCUMENT_TYPE_LIST";

export const GET_CALENDAR_EVENTS = "GET_CALENDAR_EVENTS";
export const SET_CALENDAR_EVENTS = "SET_CALENDAR_EVENTS";

export const CANCEL_BOOKING = "CANCEL_BOOKING";
export const CREATE_BOOKING = "CREATE_BOOKING";
export const UPDATE_BOOKING = "UPDATE_BOOKING";

export const GET_USER_INFO = "GET_USER_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const GET_USER_MENU = "GET_USER_MENU";
export const SET_USER_MENU = "SET_USER_MENU";

export const GET_CITIES_LIST = "GET_CITIES_LIST";
export const SET_CITIES_LIST = "SET_CITIES_LIST";

export const GET_STATE_LIST = "GET_STATE_LIST";
export const SET_STATE_LIST = "SET_STATE_LIST";

export const BLOCK_VEHICLE = "BLOCK_VEHICLE";

export const GET_BLOCKED_SHOWROOMS_LIST = "GET_BLOCKED_SHOWROOMS_LIST";
export const SET_BLOCKED_SHOWROOMS_LIST = "SET_BLOCKED_SHOWROOMS_LIST";

export const GET_GENERAL_REPORT = "GET_GENERAL_REPORT";
export const SET_GENERAL_REPORT = "SET_GENERAL_REPORT";
export const GET_LOG_REPORT = "GET_LOG_REPORT";
export const SET_LOG_REPORT = "SET_LOG_REPORT";

export const EXPORT_GENERAL_REPORT = "EXPORT_GENERAL_REPORT";
export const EXPORT_LOG_REPORT = "EXPORT_LOG_REPORT";

export const GET_VIN_LIST = "GET_VIN_LIST";
export const SET_VIN_LIST = "SET_VIN_LIST";
