import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_BLOCKED_SHOWROOMS_LIST } from "../actions/types";
import { setLoading, setBlockedShowroomsList } from "../actions";
import { getBlockedShowroomsApi } from "../api/handler";

function* handleGetBlockedShowroomsList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getBlockedShowroomsApi, args.params);
    if (response.status === 200) {
      yield put(setBlockedShowroomsList(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
  yield put(setLoading(false));
}

export default function* blockedShowroomsSaga() {
  yield all([
    takeLatest(GET_BLOCKED_SHOWROOMS_LIST, handleGetBlockedShowroomsList)
  ]);
}
