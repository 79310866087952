import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  CANCEL_BOOKING,
  CREATE_BOOKING,
  UPDATE_BOOKING
} from "../actions/types";
import {
  cancelBookingApi,
  createBookingApi,
  getCalendarEventsApi,
  updateBookingApi
} from "../api/handler";
import { setCalendarEvents, toggleNotification } from "../actions";
import { getTranslation } from "../../utils/getTranslation";
import i18next from "i18next";
import { formatMonthTranslation } from "../../utils/formatDateTime";

function* handleCreateBooking({ payload, params }) {
  try {
    const response = yield call(createBookingApi, payload);
    if (response.status === 201) {
      if (params) {
        const { data } = response.data;
        const dayTranslation = getTranslation(
          data.bookingDateTimeMessage.split(" ")[0],
          i18next.t
        );

        yield put(
          toggleNotification({
            isOpen: true,
            type: "success",
            content1: getTranslation(
              "The appointment has been successfully scheduled for",
              i18next.t
            ),
            highlight1: `${dayTranslation} ${data.bookingDateTimeMessage
              .split(" ")
              .slice(1)
              .join(" ")}`,
            content2: "",
            highlight2: ""
          })
        );
        const getCalendarEventResponse = yield call(
          getCalendarEventsApi,
          params
        );
        if (getCalendarEventResponse.status === 200) {
          const responseData = getCalendarEventResponse.data.data.map((d) => {
            return {
              ...d,
              eventStartDate: new Date(d.eventStartDate),
              eventEndDate: new Date(d.eventEndDate)
            };
          });
          yield put(setCalendarEvents(responseData));
        }
      }
    }
  } catch (e) {
    console.warn(e);
    yield put(
      toggleNotification({
        isOpen: true,
        type: "error",
        content1: getTranslation(e.response.data.message, i18next.t),
        highlight1: "",
        content2: "",
        highlight2: ""
      })
    );
  }
}

function* handleUpdateBooking({ payload, params, eventId }) {
  try {
    const response = yield call(updateBookingApi, payload, eventId);
    if (response.status === 200) {
      if (params) {
        const { data } = response.data;
        const dayTranslation = getTranslation(
          data.bookingDateTimeMessage.split(" ")[0],
          i18next.t
        );

        yield put(
          toggleNotification({
            isOpen: true,
            type: "success",
            content1: getTranslation(
              "The appointment has been successfully rescheduled for",
              i18next.t
            ),
            highlight1: `${dayTranslation} ${formatMonthTranslation(
              new Date(data.bookingDate),
              i18next.t
            )} de ${data.bookingDateTimeMessage.split(" ").slice(2).join(" ")}`,
            content2: "",
            highlight2: ""
          })
        );
        const getCalendarEventResponse = yield call(
          getCalendarEventsApi,
          params
        );
        if (getCalendarEventResponse.status === 200) {
          const responseData = getCalendarEventResponse.data.data.map((d) => {
            return {
              ...d,
              eventStartDate: new Date(d.eventStartDate),
              eventEndDate: new Date(d.eventEndDate)
            };
          });
          yield put(setCalendarEvents(responseData));
        }
      }
    }
  } catch (e) {
    console.warn(e);
    yield put(
      toggleNotification({
        isOpen: true,
        type: "error",
        content1: getTranslation(e.response.data.message, i18next.t),
        highlight1: "",
        content2: "",
        highlight2: ""
      })
    );
  }
}

function* handleCancelBooking({ id, params }) {
  try {
    const response = yield call(cancelBookingApi, id);
    if (response.status === 200) {
      if (params) {
        yield put(
          toggleNotification({
            isOpen: true,
            type: "success",
            content1: `${getTranslation(
              "The appointment has been successfully canceled",
              i18next.t
            )}`,
            highlight1: "",
            content2: ""
          })
        );

        const getCalendarEventResponse = yield call(
          getCalendarEventsApi,
          params
        );
        if (getCalendarEventResponse.status === 200) {
          const responseData = getCalendarEventResponse.data.data.map((d) => {
            return {
              ...d,
              eventStartDate: new Date(d.eventStartDate),
              eventEndDate: new Date(d.eventEndDate)
            };
          });
          yield put(setCalendarEvents(responseData));
        }
      }
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* bookingSaga() {
  yield all([
    takeLatest(CANCEL_BOOKING, handleCancelBooking),
    takeLatest(CREATE_BOOKING, handleCreateBooking),
    takeLatest(UPDATE_BOOKING, handleUpdateBooking)
  ]);
}
