import "./demoFilters.css";
import Button from "../../../components/Button/Button";
import TextInput from "../../../components/TextInput/TextInput";
import Dropdown from "../../../components/Dropdown/Dropdown";
import filterOptions from "../../../db/filterOptions";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const DemoFilter = ({
  filters,
  handleFiltersChange,
  resetPageToZero,
  handleClearFilters,
  handleSearchFilters
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    handleFiltersChange({ ...filters, value: e.target.value });
    resetPageToZero();
  };

  const handleDropdownChange = (e) => handleFiltersChange({ value: "", name: e.target.value });

  return (
    <div className="demo-filters">
      <span className="demo-filter-title">{getTranslation("Select filter", t)}</span>
      <div className="row">
        <TextInput
          label={
            getTranslation(filterOptions.find((item) => item.value === filters.name)?.name, t) ??
            "VIN"
          }
          className="col-xl-3 ps-0 pe-xl-5 pe-sm-0 mb-lg-0 mb-3 align-self-end"
          handleChange={handleChange}
          value={filters.value}
          name={filters.name?.value ?? "vin"}
        />
        <Dropdown
          label={getTranslation("Filter by", t)}
          className="col-xl-3 ps-0 pe-xl-5 pe-sm-0 align-self-end"
          data={filterOptions.map((item) => ({
            ...item,
            name: getTranslation(item.name, t)
          }))}
          value={filters.name}
          handleChange={handleDropdownChange}
          textField="name"
          valueField="value"
        />
        <div className="col-xl-3 ps-0 pe-5 mb-4 mb-xl-0"></div>
        <div className="btns col-xl-3 ps-0 pe-0 d-flex justify-content-between flex-sm-wrap flex-xxl-nowrap justify-content-lg-between gap-lg-4">
          <Button
            type="secondary"
            children={getTranslation("Clean", t)}
            onClick={() => handleClearFilters({})}
          />
          <Button
            type="primary"
            children={getTranslation("Search", t)}
            onClick={handleSearchFilters}
          />
        </div>
      </div>
    </div>
  );
};

export default DemoFilter;
