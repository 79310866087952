import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AuthRoutes from "./AuthRoutes";
import { useSelector } from "react-redux";
import { Suspense } from "react";

const AppRouter = () => {
  const isAuth = useSelector((state) => state.isAuth);

  return (
    <Suspense fallback={<div></div>}>
      <Routes>
        {(isAuth ? AuthRoutes : AppRoutes).map((route, index) => {
          return !route.children ? (
            <Route key={index} path={route.path} element={route.element} />
          ) : (
            <Route key={index} path={route.path} element={route.element}>
              {route.children.map((child, idx) => (
                <Route key={idx} path={child.path} element={child.element} />
              ))}
            </Route>
          );
        })}
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
