import { CgCloseO } from "react-icons/cg";
import editIcon from "../../assets/icons/edit_icon.svg";
import { IoTrashOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const GridActionsCell = ({
  row,
  handleDelete,
  handleDuplicate,
  handleEdit,
  disabledDuplicate,
  disabledEdit,
  disabledDelete,
  type
}) => {
  const { t } = useTranslation();
  const role = useSelector((state) => state.userInfo)?.accessLevel;

  return (
    <div className="action-icons">
      {type === "block" && role !== 3 && (
        <Tooltip title={getTranslation("Create Block", t)} arrow placement="top">
          <span
            className={disabledDuplicate ? "disabled" : ""}
            onClick={disabledDuplicate ? undefined : () => handleDuplicate(row)}
          >
            <CgCloseO />
          </span>
        </Tooltip>
      )}
      {type === "block" ? (
        <Tooltip title={getTranslation("Edit Block", t)} arrow placement="top">
          <span
            className={disabledEdit ? "disabled" : ""}
            onClick={disabledEdit ? undefined : () => handleEdit(row)}
          >
            <img src={editIcon} alt="edit icon" />
          </span>
        </Tooltip>
      ) : (
        <span
          className={disabledEdit ? "disabled" : ""}
          onClick={disabledEdit ? undefined : () => handleEdit(row)}
        >
          <img src={editIcon} alt="edit icon" />
        </span>
      )}
      {type === "block" ? (
        <Tooltip title={getTranslation("Remove Block", t)}arrow placement="top-start">
          <span
            className={disabledDelete ? "disabled" : ""}
            onClick={disabledDelete ? undefined : () => handleDelete(row)}
          >
            <IoTrashOutline />
          </span>
        </Tooltip>
      ) : (
        <span
          className={disabledDelete ? "disabled" : ""}
          onClick={disabledDelete ? undefined : () => handleDelete(row)}
        >
          <IoTrashOutline />
        </span>
      )}
    </div>
  );
};

export default GridActionsCell;
