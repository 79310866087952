import { Navigate } from "react-router-dom";
import InvalidLogin from "../pages/Login/InvalidLogin/InvalidLogin";
import Landing from "../pages/Login/Landing/Landing";

const AppRoutes = [
  {
    path: "/login",
    element: <Landing />
  },
  {
    path: "/invalid",
    element: <InvalidLogin />
  },
  {
    path: "*",
    element: <Navigate to="/login" />
  }
];

export default AppRoutes;
