import Content from "../layout/Content/Content";
import ManageBlocks from "../pages/ManageBlocks/ManageBlocks";
import ManageDemos from "../pages/ManageDemos/ManageDemos";
import ManageShowrooms from "../pages/ManageShowrooms/ManageShowrooms";
import ManageCalendar from "../pages/ManageCalendar/ManageCalendar";
import Reports from "../pages/Reports/Reports";
import ProtectedRoute from "./ProtectedRoute";

const AuthRoutes = [
  {
    element: <Content />,
    children: [
      {
        path: "/showrooms",
        element: (
          <ProtectedRoute>
            <ManageShowrooms />
          </ProtectedRoute>
        )
      },
      {
        path: "/demos",
        element: (
          <ProtectedRoute>
            <ManageDemos />
          </ProtectedRoute>
        )
      },
      {
        path: "/blocks",
        element: (
          <ProtectedRoute>
            <ManageBlocks />
          </ProtectedRoute>
        )
      },
      {
        path: "/calendar",
        element: (
          <ProtectedRoute>
            <ManageCalendar />
          </ProtectedRoute>
        )
      },
      {
        path: "/reports",
        element: <Reports />
      },
      {
        path: "*",
        element: <ProtectedRoute />
      }
    ]
  }
];
export default AuthRoutes;
