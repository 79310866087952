import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_VARIANT_LIST } from "../actions/types";
import { getCommonVariantListApi } from "../api/handler";
import { setVariantList } from "../actions";

function* handleLoadVariantList(args) {
  try {
    const response = yield call(getCommonVariantListApi, args.params);
    if (response.status === 200) {
      yield put(setVariantList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* variantSaga() {
  yield all([takeLatest(GET_VARIANT_LIST, handleLoadVariantList)]);
}
