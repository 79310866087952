import React, { useEffect, useRef, useState } from "react";
import "./manageDemos.css";
import DemoFilter from "./DemoFilters/DemoFilters";
import Button from "../../components/Button/Button";
import DemoGrid from "./DemoGrid/DemoGrid";
import CreateDemoModals from "../../components/Modals/CreateDemoModal/CreateDemoModals";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import useGridFunctionalities from "../../hooks/useGridFunctionalities";
import { useDispatch } from "react-redux";
import { getDemoList } from "../../redux-sagas/actions";

const ManageDemos = () => {
	const { t } = useTranslation();
	const isInitial = useRef(true);
	const dispatch = useDispatch();
	const [showDemoModal, setShowDemoModal] = useState(false);
	const [mode, setMode] = useState("create");
	const [selected, setSelected] = useState(null);

	const {
		paginationModel,
		sort,
		handlePaginationChange,
		handleSortChange,
		getPageSkip,
		filters,
		handleFiltersChange,
		resetPageToZero,
		handleClearFilters,
		handleSearchFilters
	} = useGridFunctionalities();

	const toggleDemoModal = () => setShowDemoModal(!showDemoModal);
	const openCreateModal = () => {
		setMode("create");
		toggleDemoModal();
	};

	const buildQuery = () => {
		let filterQuery = JSON.stringify({
			field: filters.name || "vin",
			value: filters.value || "",
			condition: "contains"
		});

		return `?filters=[${filterQuery}]&sort=${JSON.stringify(sort)}&page=${JSON.stringify({
			skip: getPageSkip(),
			take: paginationModel.pageSize
		})}`;
	};

	useEffect(() => {
		const getData = () => dispatch(getDemoList(buildQuery()));

		if (!isInitial.current) {
			const getDataWithBuffer = setTimeout(getData, 200);
			return () => clearTimeout(getDataWithBuffer);
		} else {
			isInitial.current = false;
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters.value, sort, paginationModel]);

	return (
		<div className='cont demos'>
			<DemoFilter
				filters={filters}
				handleFiltersChange={handleFiltersChange}
				resetPageToZero={resetPageToZero}
				handleClearFilters={handleClearFilters}
				handleSearchFilters={handleSearchFilters}
			/>
			<div className='grid'>
				<Button
					type='create'
					children={getTranslation("Create demo", t)}
					onClick={openCreateModal}
				/>
				<DemoGrid
					setSelected={setSelected}
					setMode={setMode}
					toggleEditModal={toggleDemoModal}
					paginationModel={paginationModel}
					handlePaginationChange={handlePaginationChange}
					handleSortChange={handleSortChange}
					buildQuery={buildQuery}
				/>
			</div>
			{showDemoModal && (
				<CreateDemoModals
					selected={selected}
					toggleModal={toggleDemoModal}
					open={showDemoModal}
					mode={mode}
					buildQuery={buildQuery}
				/>
			)}
		</div>
	);
};

export default ManageDemos;
