import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_GENERAL_REPORT, GET_LOG_REPORT } from "../actions/types";
import { setGeneralReport, setLoading, setLogReport } from "../actions";
import { getGeneralReportApi, getLogReportApi } from "../api/handler";

function* handleLoadGeneralReport({ params }) {
  yield put(setLoading(true));
  try {
    const response = yield call(getGeneralReportApi, params);
    if (response.status === 200) {
      yield put(setGeneralReport(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

function* handleLoadLogReport({ params }) {
  yield put(setLoading(true));
  try {
    const response = yield call(getLogReportApi, params);
    if (response.status === 200) {
      yield put(setLogReport(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
  yield put(setLoading(false));
}

export default function* reportsSaga() {
  yield all([
    takeLatest(GET_GENERAL_REPORT, handleLoadGeneralReport),
    takeLatest(GET_LOG_REPORT, handleLoadLogReport)
  ]);
}
