import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select
} from "@mui/material";
import "./multiselect.css";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Multiselect = ({
  label,
  className = "",
  value,
  handleChange,
  data,
  error,
  onOpen,
  name,
  subtext
}) => {
  const { t } = useTranslation();
  return (
    <div className={`multiselect-input ${className} ${error ? "error" : ""}`}>
      <FormControl variant="standard">
        <InputLabel>{label}</InputLabel>
        <Select
          onOpen={onOpen}
          name={name}
          multiple
          value={value ?? []}
          onChange={handleChange}
          renderValue={(selected) =>
            selected.includes(0)
              ? getTranslation("All brands", t)
              : data
                .filter((item) => selected.indexOf(item.id) > -1)
                .map((item) => item.name)
                .join(", ")
          }
        >
          {data?.map((datum) => (
            <MenuItem key={datum.id} value={datum.id}>
              <Checkbox checked={value?.indexOf(datum.id) > -1} />
              <ListItemText primary={datum.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <span className={error ? "error" : "subtext"}>
        {error ? getTranslation("Fill in this field correctly.", t) : subtext}
      </span>
    </div>
  );
};

export default Multiselect;
