import { DataGrid } from "@mui/x-data-grid";
import "./showroomGrid.css";
import { useDispatch, useSelector } from "react-redux";
import {
	toggleDeleteModal,
	toggleReassignModal,
	getShowroomList,
	toggleNotification
} from "../../../redux-sagas/actions";
import fetchClient from "../../../services/fetch";
import { fetchShowroomColumns } from "../../../utils/fetchColumns";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const ShowroomGrid = ({
	toggleModal,
	setMode,
	paginationModel,
	buildQuery,
	setSelected,
	handlePaginationChange,
	handleSortChange
}) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isLoading = useSelector((state) => state.isLoading);
	const showroomListInfo = useSelector((state) => state.showroomList);

	const handleEditClick = (params) => {
		setMode("edit");
		setSelected(params);
		toggleModal();
	};

	const openReassignModal = () =>
		dispatch(
			toggleReassignModal({
				isOpen: true,
				title: getTranslation("Reschedule showroom events", t),
				content: t("showroom_reassign_events")
			})
		);

	const openDeleteModal = (params) =>
		dispatch(
			toggleDeleteModal({
				isOpen: true,
				title: getTranslation("Delete Showroom", t),
				content1: getTranslation("Are you sure you want to delete", t),
				highlight: params.showroomName,
				content2: getTranslation("permanently?", t),
				closeCallback: () => handleDelete(params)
			})
		);

	const handleDelete = (params) =>
		fetchClient()
			.delete(`/showrooms/${params.showroomId}`)
			.then(() => {
				dispatch(getShowroomList(buildQuery()));
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: getTranslation("The showroom", t),
						highlight1: params.showroomName,
						content2: `${getTranslation("has been", t)} ${t("deleted_el")} ${getTranslation(
							"successfully",
							t
						)}.`,
						highlight2: ""
					})
				);
			})
			.catch((err) => {
				console.log(err);
				openReassignModal();
			});

	const handleDeleteClick = (params) => {
		if (!params.deletable) openReassignModal();
		else openDeleteModal(params);
	};

	const handleChangeStatus = (params) =>
		fetchClient()
			.put(`/showrooms/${params.showroomId}/status/${!params.isActive}`)
			.then(() => dispatch(getShowroomList(buildQuery())))
			.catch((err) => console.log(err));

	return (
		<DataGrid
			getRowId={(row) => row.showroomId}
			getRowHeight={() => "auto"}
			onSortModelChange={(newSortModel) => handleSortChange(newSortModel)}
			columns={fetchShowroomColumns(handleEditClick, handleChangeStatus, handleDeleteClick).map(
				(item) => ({
					...item,
					headerName: getTranslation(item.headerName, t)
				})
			)}
			rows={showroomListInfo.data}
			disableColumnMenu
			rowCount={showroomListInfo.totalRecords || 0}
			paginationModel={paginationModel}
			loading={isLoading}
			pageSizeOptions={[5, 10, 15, 20]}
			slotProps={{
				pagination: {
					labelRowsPerPage: getTranslation("Rows per page:", t)
				}
			}}
			paginationMode='server'
			onPaginationModelChange={(paginationModel) => handlePaginationChange(paginationModel)}
			disableRowSelectionOnClick
		/>
	);
};

export default ShowroomGrid;
