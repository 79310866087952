import { getTranslation } from "./getTranslation";
import { formatDate, formatTime } from "./formatDateTime";
import { format } from "date-fns";
import i18next from "i18next";

const getCalendarFormattedDateTime = (payload) => {
	const newPayload = {
		...payload,
		startDate: formatDate(payload.startDate),
		endDate: formatDate(payload.endDate)
	};

	const formatted = {};

	if (newPayload.startDate === newPayload.endDate) {
		formatted.week = getTranslation(format(new Date(payload.startDate), "EEEE"), i18next.t);
		formatted.days = format(new Date(payload.startDate), "dd");
	}

	formatted.multiple =
		getTranslation(format(new Date(payload.startDate), "EEEE"), i18next.t) +
		" " +
		format(new Date(payload.startDate), "dd") +
		" - " +
		getTranslation(format(new Date(payload.endDate), "EEEE"), i18next.t) +
		" " +
		format(new Date(payload.endDate), "dd");

	if (!newPayload.isAllDay) {
		newPayload.startTime = formatTime(payload.startTime);
		newPayload.endTime = formatTime(payload.endTime);

		formatted.startTime = format(new Date(payload.startTime), "hh:mm");
		formatted.endTime = format(new Date(payload.endTime), "p");
	} else {
		// fetchClient()
		// 	.get(`/showrooms/${payload.showroomIds[0]}`)
		// 	.then((res) => {
		// 		const startDay = format(payload.startDate.$d, "EEEE").toLowerCase();
		// 		const endDay = format(payload.endDate.$d, "EEEE").toLowerCase();

		// 		newPayload.startTime = res.data.data[startDay + "StartTime"];
		// 		newPayload.endTime = res.data.data[endDay + "EndTime"];

		// 		formatted.startTime = format(createNewDate(newPayload.startTime), "hh:mm");

		// 		formatted.endTime = format(createNewDate(newPayload.endTime), "p");
		// 	});
		formatted.startTime = "12:00";
		formatted.endTime = "11:59 PM";
	}
	return { formattedDateTime: formatted, newPayload };
};

export default getCalendarFormattedDateTime;
