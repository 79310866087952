import moment from "moment";
import { formatDate } from "./formatDateTime";

const getStartEndDates = (date, view) => {
  let start, end;

  if (view === "day") {
    start = moment(date).startOf("day");
    end = moment(date).endOf("day");
  } else if (view === "week") {
    start = moment(date).startOf("week");
    end = moment(date).endOf("week");
  } else if (view === "month") {
    start = moment(date).startOf("month").startOf("week");
    end = moment(date).endOf("month").endOf("week");
  }

  return { from: formatDate(start), to: formatDate(end) };
};

export default getStartEndDates;
