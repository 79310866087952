import { Dialog } from "@mui/material";
import Button from "../../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { toggleDeleteModal } from "../../../redux-sagas/actions";
import "./deleteModal.css";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const DeleteModal = () => {
  const { t } = useTranslation();
  const { title, content1, isOpen, highlight, content2, closeCallback } =
    useSelector((state) => state.deleteModal);
  const dispatch = useDispatch();

  const closeModal = (type) => {
    dispatch(
      toggleDeleteModal({
        isOpen: false,
        title,
        content1,
        highlight,
        content2
      })
    );

    if (closeCallback && type === "accept") closeCallback();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={closeModal}
      className="delete-reassign-modal"
    >
      <h2>{title}</h2>
      <div className="content">
        <span>{content1}</span> <strong>{highlight}</strong>{" "}
        <span>{content2}</span>
      </div>
      <div className="btns">
        <Button onClick={() => closeModal("cancel")} type="secondary">
          {getTranslation("Cancel", t)}
        </Button>
        <Button onClick={() => closeModal("accept")} type="primary">
        {getTranslation("Accept", t)}
        </Button>
      </div>
    </Dialog>
  );
};

export default DeleteModal;
