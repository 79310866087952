const dayList = [
  {
    day: "monday",
    display: "Monday"
  },
  {
    day: "tuesday",
    display: "Tuesday"
  },
  {
    day: "wednesday",
    display: "Wednesday"
  },
  {
    day: "thursday",
    display: "Thursday"
  },
  {
    day: "friday",
    display: "Friday"
  },
  {
    day: "saturday",
    display: "Saturday"
  },
  {
    day: "sunday",
    display: "Sunday"
  }
];

export default dayList;
