import { FaPlus, FaPencil, FaTrashCan } from "react-icons/fa6";
import { FiFilePlus } from "react-icons/fi";
import calendarIcon from "../../assets/icons/calendar.svg";
import { GrDownload } from "react-icons/gr";
import "./button.css";

const Button = ({ type, children, onClick, disabled, className = "" }) => {
  switch (type) {
    case "create":
      return (
        <button
          className={className + " button " + type}
          onClick={onClick}
          disabled={disabled || false}
        >
          <div>
            <FaPlus />
          </div>
          {children}
        </button>
      );
    case "multiple":
      return (
        <button
          className={className + " button " + type}
          onClick={onClick}
          disabled={disabled || false}
        >
          <div>
            <FiFilePlus />
          </div>
          {children}
        </button>
      );
    case "calendar":
      return (
        <button
          onClick={onClick}
          disabled={disabled || false}
          className={className + " button " + type}
        >
          <div>
            <img src={calendarIcon} alt="calendar icon" />
          </div>
          {children}
        </button>
      );
    case "edit":
      return (
        <button
          className={className + " button " + type}
          onClick={onClick}
          disabled={disabled || false}
        >
          <div>
            <FaPencil />
          </div>
          {children}
        </button>
      );
    case "trash":
      return (
        <button
          className={className + " button " + type}
          onClick={onClick}
          disabled={disabled || false}
        >
          <div>
            <FaTrashCan />
          </div>
          {children}
        </button>
      );
    case "export":
      return (
        <button
          className={className + " button " + type}
          onClick={onClick}
          disabled={disabled || false}
        >
          <div>
            <GrDownload />
          </div>
          {children}
        </button>
      );
    default:
      return (
        <button
          className={className + " button " + type}
          onClick={onClick}
          disabled={disabled || false}
        >
          {children}
        </button>
      );
  }
};

export default Button;
