import "./hourSection.css";
import DayRow from "./DayRow/DayRow";
import { Checkbox, FormControlLabel } from "@mui/material";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const HourSection = ({
  payload,
  handleTimepickerChange,
  handleSwitchChange,
  hourData,
  errors
}) => {
  const { t } = useTranslation();
  
  return (
    <div className="business-hrs">
      <h3>{getTranslation("Showroom hours", t)}</h3>
      <span>{getTranslation("Select the hours of operation", t)}</span>
      <div className="day-rows">
        {hourData.map((day) => (
          <DayRow
            key={day.day}
            payload={payload}
            handleSwitchChange={handleSwitchChange}
            handleTimepickerChange={handleTimepickerChange}
            day={day}
            errors={errors}
          />
        ))}
      </div>
      <FormControlLabel
        control={
          <Checkbox
            name="copyMondaySchedule"
            onChange={handleSwitchChange}
            checked={payload.copyMondaySchedule ?? false}
          />
        }
        label={getTranslation("Copy Monday's schedule for the whole week", t)}
      />
    </div>
  );
};

export default HourSection;
