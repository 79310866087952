import fetchClient from "../../services/fetch";

export const getShowroomListApi = (params) =>
  fetchClient().get("/showrooms" + params);

export const getDemoListApi = (params) =>
  fetchClient().get("/vehicles" + params);

export const getBrandListApi = () => fetchClient().get("/brands");

export const getModelListApi = () => fetchClient().get("/models");

export const getVariantListApi = () => fetchClient().get("/variants");

export const getFuelTypeListApi = () => fetchClient().get("/fueltypes");

/* Common Data */
export const getCommonBrandListApi = (params) =>
  fetchClient().get("/commondata/brands" + (params ? params : ""));

export const getCommonModelListApi = (params) =>
  fetchClient().get("/commondata/models" + (params ? params : ""));

export const getCommonVariantListApi = (params) =>
  fetchClient().get("/commondata/variants" + (params ? params : ""));

export const getCommonShowroomListApi = (params) =>
  fetchClient().get("/commondata/showrooms" + (params ? params : ""));

export const getCommonCitiesListApi = (params) =>
  fetchClient().get("/commondata/cities" + params);

export const getCommonFuelTypeListApi = (params) =>
  fetchClient().get("/commondata/fueltypes" + (params ? params : ""));

export const getDocumentTypeListApi = () =>
  fetchClient().get("/commondata/documenttypes");

export const getVinListApi = (params) =>
  fetchClient().get("/commondata/vins" + params);

/* Calendar */
export const getCalendarEventsApi = (params) =>
  fetchClient().get("calendars" + params);

/* Booking */
export const createBookingApi = (payload) =>
  fetchClient().post(`/bookings`, payload);
export const cancelBookingApi = (id) =>
  fetchClient().put(`/bookings/${id}/cancel`);
export const updateBookingApi = (payload, eventId) =>
  fetchClient().put(`/bookings/${eventId}`, payload);

/* Users */
export const getUserMenuApi = () => fetchClient().get("users/menu");

export const getUserInfoApi = () => fetchClient().get("users/info");

/* Blocking */
export const blockVehicleApi = (blockData) =>
  fetchClient().post("blockedvehicles", blockData);

export const getBlockDemoListApi = (params) =>
  fetchClient().get("/blockedvehicles" + params);

export const getBlockedShowroomsApi = (params) =>
  fetchClient().get("/blockedshowrooms" + params);

export const getGeneralReportApi = (params) =>
  fetchClient().get("/reports/general" + params);

export const getLogReportApi = (params) =>
  fetchClient().get("/reports/log" + params);

export const getExportGeneralReportApi = (params) =>
  fetchClient().get(`/export/reports/general${params}`, {
    responseType: "arraybuffer"
  });

export const getExportLogReportApi = (params) =>
  fetchClient().get(`/export/reports/log${params}`, {
    responseType: "arraybuffer"
  });
