import {
  GET_DEMO_LIST,
  GET_SHOWROOM_LIST,
  GET_BRAND_LIST,
  GET_MODEL_LIST,
  GET_VARIANT_LIST,
  GET_USER_INFO,
  GET_USER_MENU,
  SET_AUTH,
  SET_DEMO_LIST,
  SET_BRAND_LIST,
  SET_LOADING,
  SET_MODEL_LIST,
  SET_SHOWROOM_LIST,
  SET_USER_INFO,
  SET_USER_MENU,
  SET_USER_SETTINGS,
  SET_VARIANT_LIST,
  TOGGLE_DELETE_MODAL,
  TOGGLE_NOTIFICATION,
  TOGGLE_REASSIGN_MODAL,
  SET_FUEL_TYPE_LIST,
  GET_FUEL_TYPE_LIST,
  GET_CALENDAR_EVENTS,
  SET_CALENDAR_EVENTS,
  CANCEL_BOOKING,
  GET_CITIES_LIST,
  SET_CITIES_LIST,
  BLOCK_VEHICLE,
  GET_BLOCK_DEMO_LIST,
  SET_BLOCK_DEMO_LIST,
  GET_BLOCKED_SHOWROOMS_LIST,
  SET_BLOCKED_SHOWROOMS_LIST,
  GET_DOCUMENT_TYPE_LIST,
  SET_DOCUMENT_TYPE_LIST,
  CREATE_BOOKING,
  GET_COMMON_SHOWROOM_LIST,
  SET_COMMON_SHOWROOM_LIST,
  UPDATE_BOOKING,
  GET_GENERAL_REPORT,
  SET_GENERAL_REPORT,
  GET_LOG_REPORT,
  SET_LOG_REPORT,
  EXPORT_GENERAL_REPORT,
  EXPORT_LOG_REPORT,
  GET_VIN_LIST,
  SET_VIN_LIST
} from "./types";

export const setAuth = (isAuth) => ({
  type: SET_AUTH,
  isAuth
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  isLoading
});

export const setUserSettings = (userSettings) => ({
  type: SET_USER_SETTINGS,
  userSettings
});

export const toggleDeleteModal = (deleteModalValues) => ({
  type: TOGGLE_DELETE_MODAL,
  deleteModalValues
});

export const toggleReassignModal = (reassignModalValues) => ({
  type: TOGGLE_REASSIGN_MODAL,
  reassignModalValues
});

export const toggleNotification = (notificationValues) => ({
  type: TOGGLE_NOTIFICATION,
  notificationValues
});

export const loadBrandList = (params) => ({
  type: GET_BRAND_LIST,
  params
});

export const setBrandList = (brandList) => ({
  type: SET_BRAND_LIST,
  brandList
});

export const setShowroomList = (showroomList) => ({
  type: SET_SHOWROOM_LIST,
  showroomList
});

export const getShowroomList = (params) => ({
  type: GET_SHOWROOM_LIST,
  params
});

export const getDemoList = (params) => ({
  type: GET_DEMO_LIST,
  params
});

export const setDemoList = (demoList) => ({
  type: SET_DEMO_LIST,
  demoList
});

export const loadModelList = (params) => ({
  type: GET_MODEL_LIST,
  params
});

export const setModelList = (modelList) => ({
  type: SET_MODEL_LIST,
  modelList
});

export const loadVariantList = (params) => ({
  type: GET_VARIANT_LIST,
  params
});

export const setVariantList = (variantList) => ({
  type: SET_VARIANT_LIST,
  variantList
});

export const loadCommonShowroomList = (params) => ({
  type: GET_COMMON_SHOWROOM_LIST,
  params
});

export const setCommonShowroomList = (commonShowroomList) => ({
  type: SET_COMMON_SHOWROOM_LIST,
  commonShowroomList
});

export const loadFuelTypeList = (params) => ({
  type: GET_FUEL_TYPE_LIST,
  params
});

export const setFuelTypeList = (fuelTypeList) => ({
  type: SET_FUEL_TYPE_LIST,
  fuelTypeList
});

export const loadDocumentTypeList = () => ({
  type: GET_DOCUMENT_TYPE_LIST
});

export const setDocumentTypeList = (documentTypeList) => ({
  type: SET_DOCUMENT_TYPE_LIST,
  documentTypeList
});

export const loadCalendarEvents = (params) => ({
  type: GET_CALENDAR_EVENTS,
  params
});

export const setCalendarEvents = (calendarEvents) => ({
  type: SET_CALENDAR_EVENTS,
  calendarEvents
});

export const cancelBooking = ({ id, params }) => ({
  type: CANCEL_BOOKING,
  id,
  params
});

export const createBooking = (payload, params) => ({
  type: CREATE_BOOKING,
  payload,
  params
});

export const updateBooking = (payload, params, eventId) => ({
  type: UPDATE_BOOKING,
  payload,
  params,
  eventId
});

export const getUserInfo = () => ({
  type: GET_USER_INFO
});

export const getUserMenu = () => ({
  type: GET_USER_MENU
});

export const setUserInfo = (userInfo) => ({
  type: SET_USER_INFO,
  userInfo
});

export const setUserMenu = (userMenu) => ({
  type: SET_USER_MENU,
  userMenu
});

export const getCitiesList = (params) => ({
  type: GET_CITIES_LIST,
  params
});

export const setCitiesList = (citiesList) => ({
  type: SET_CITIES_LIST,
  citiesList
});

export const blockVehicle = (blockData) => ({
  type: BLOCK_VEHICLE,
  blockData
});

export const getBlockDemoList = (params) => ({
  type: GET_BLOCK_DEMO_LIST,
  params
});

export const setBlockDemoList = (blockDemoList) => ({
  type: SET_BLOCK_DEMO_LIST,
  blockDemoList
});

export const getBlockedShowroomsList = (params) => ({
  type: GET_BLOCKED_SHOWROOMS_LIST,
  params
});

export const setBlockedShowroomsList = (blockedShowroomsList) => ({
  type: SET_BLOCKED_SHOWROOMS_LIST,
  blockedShowroomsList
});

export const getGeneralReport = (params) => ({
  type: GET_GENERAL_REPORT,
  params
});

export const setGeneralReport = (generalReport) => ({
  type: SET_GENERAL_REPORT,
  generalReport
});

export const getLogReport = (params) => ({
  type: GET_LOG_REPORT,
  params
});

export const setLogReport = (logReport) => ({
  type: SET_LOG_REPORT,
  logReport
});

export const exportGeneralReport = (params) => ({
  type: EXPORT_GENERAL_REPORT,
  params
});

export const exportLogReport = (params) => ({
  type: EXPORT_LOG_REPORT,
  params
});

export const loadVinList = (params) => ({
  type: GET_VIN_LIST,
  params
});

export const setVinList = (vinList) => ({
  type: SET_VIN_LIST,
  vinList
});
