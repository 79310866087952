import { takeLatest, all, call } from "redux-saga/effects";
import {
  getExportGeneralReportApi,
  getExportLogReportApi
} from "../api/handler";
import { EXPORT_GENERAL_REPORT, EXPORT_LOG_REPORT } from "../actions/types";

function handleDownloadFile(response) {
  const fileName = response.headers.get("content-disposition").split('"')[1];
  const blobObj = new Blob([response.data], {
    type: response.headers.get("content-type")
  });

  const url = URL.createObjectURL(blobObj);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.append(link);

  link.click();
  link.remove();
}

function* handleExportGeneralReport({ params }) {
  try {
    const response = yield call(getExportGeneralReportApi, params);
    if (response.status === 200) handleDownloadFile(response);
  } catch (e) {
    console.warn(e);
  }
}

function* handleExportLogReport({ params }) {
  try {
    const response = yield call(getExportLogReportApi, params);
    if (response.status === 200) handleDownloadFile(response);
  } catch (e) {
    console.warn(e);
  }
}

export default function* exportSaga() {
  yield all([
    takeLatest(EXPORT_GENERAL_REPORT, handleExportGeneralReport),
    takeLatest(EXPORT_LOG_REPORT, handleExportLogReport)
  ]);
}
