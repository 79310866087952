const statusFilter = [
  {
    name: "Active",
    value: true,
    id: 0
  },
  {
    name: "Inactive",
    value: false,
    id: 1
  }
];

export default statusFilter;
