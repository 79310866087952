import React, { useCallback, useEffect, useRef, useState } from "react";
import "./createBlockDemoModal.css";
import { Dialog } from "@mui/material";
import InformationSection from "./BlockInformationSection/InformationSection";
import VehicleSection from "./BlockVehicleSection/VehicleSection";
import ReasonSection from "./BlockReasonSection/ReasonSection";
import Button from "../../Button/Button";
import { useDispatch } from "react-redux";
import fetchClient from "../../../services/fetch";
import { createNewDates } from "../../../utils/formatDateTime";
import { getBlockDemoList, toggleNotification } from "../../../redux-sagas/actions";
import dayjs from "dayjs";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import getCalendarFormattedDateTime from "../../../utils/getCalendarFormattedDateTime";

const CreateBlockDemoModal = ({
	open,
	mode,
	closeModal,
	buildQuery,
	setRowSelectionModel,
	selectedMultiple,
	setSelectedMultiple,
	calendarSlot,
	view
}) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const shouldSubmit = useRef(false);
	const [payload, setPayload] = useState({
		vehicleIds: mode === "create" ? [] : selectedMultiple.map((item) => item.vehicleId),
		startDate: calendarSlot ? dayjs(calendarSlot.start) : null,
		endDate: calendarSlot ? dayjs(calendarSlot.start) : null,
		startTime: calendarSlot ? dayjs(calendarSlot.start) : null,
		endTime: calendarSlot ? dayjs(calendarSlot.end) : null
	});
	const [dropdownData, setDropdownData] = useState({
		brands: [],
		models: [],
		vins: []
	});
	const [dropdownValue, setDropdownValue] = useState({});
	const [errors, setErrors] = useState({});
	const isGridView = view === "grid";

	const fetchBlockData = useCallback(
		() =>
			fetchClient()
				.get(`/blockedvehicles/${selectedMultiple[0].scheduleId}`)
				.then((res) => {
					const data = res.data.data;
					setPayload({ ...createNewDates(data), vehicleIds: [data.vehicleId] });
				})
				.catch((err) => console.log(err)),
		[selectedMultiple]
	);

	const sendRequest = useCallback(() => {
		const { formattedDateTime, newPayload } = getCalendarFormattedDateTime(payload);

		(mode === "edit" ? fetchClient().put : fetchClient().post)(
			"/blockedvehicles" + (mode === "edit" ? `/${selectedMultiple[0].scheduleId}` : ""),
			newPayload
		)
			.then(() => {
				const gridSuccessMsg = `${
					mode === "multiple"
						? getTranslation("Multiple blocks have been", t)
						: getTranslation("The block has been", t)
				} ${mode === "edit" ? t("edited_el") : t("created_el")} ${getTranslation(
					"successfully",
					t
				)}.`;
				const calendarSuccessContent1 = getTranslation("The block with reason", t);

				dispatch(getBlockDemoList(buildQuery()));
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "success",
						content1: isGridView ? gridSuccessMsg : calendarSuccessContent1,
						highlight1: isGridView ? "" : getTranslation(newPayload.reason, t),
						content2: isGridView ? "" : getTranslation("has been successfully created for", t),
						highlight2: isGridView
							? ""
							: ` ${
									newPayload.startDate === newPayload.endDate
										? `${formattedDateTime.week} ${formattedDateTime.days}`
										: formattedDateTime.multiple
							  }, ${formattedDateTime.startTime} - ${formattedDateTime.endTime}`
					})
				);
			})
			.catch((err) =>
				dispatch(
					toggleNotification({
						isOpen: true,
						type: "error",
						content1: getTranslation(err.response.data.message, t),
						highlight1: "",
						content2: "",
						highlight2: ""
					})
				)
			)
			.finally(() => {
				setRowSelectionModel([]);
				setSelectedMultiple([]);
			});
		closeModal("accept");
	}, [
		buildQuery,
		closeModal,
		dispatch,
		mode,
		payload,
		selectedMultiple,
		setRowSelectionModel,
		setSelectedMultiple,
		t
	]);

	useEffect(() => {
		if (mode === "edit") {
			fetchBlockData();
		}
	}, [fetchBlockData, mode]);

	useEffect(() => {
		if (!Object.keys(errors).length && shouldSubmit.current) sendRequest();
	}, [errors, sendRequest]);

	const handleChangeDropdown = (e) => {
		setPayload({
			...payload,
			[e.target.name]: e.target.value
		});
	};

	const handleDropdown = (e) => {
		setDropdownValue({
			...dropdownValue,
			[e.target.name]: e.target.value
		});
		if (e.target.name === "vehicleId") {
			setPayload({
				...payload,
				vehicleIds: [e.target.value]
			});
		}
	};

	const handleChangeCheckbox = (e) => {
		const { checked, name } = e.target;
		const newObj = { ...payload, [name]: checked };

		if (checked) {
			newObj.startTime = newObj.endTime = null;
		}

		setPayload(newObj);
	};

	const handleChangeDateTime = (newValue, name) => setPayload({ ...payload, [name]: newValue });

	const validate = () => {
		const obj = {};
		if (!payload.startDate) obj.startDate = true;
		if (!payload.endDate) obj.endDate = true;
		if (!payload.isAllDay) {
			if (!payload.startTime) obj.startTime = true;
			if (!payload.endTime) obj.endTime = true;
		}
		if (!payload.vehicleIds.length) obj.vehicleId = true;
		if (!payload.reason) obj.reason = true;

		if (payload.isAllDay) {
			setPayload({ ...payload, startTime: null, endTime: null });
		}
		
		setErrors(obj);
	};

	const getDropdownData = useCallback(
		(name) => {
			const params = new URLSearchParams({
				...dropdownValue
			});
			fetchClient()
				.get(`/blockedvehicles/${name}?${params}`)
				.then((res) => {
					setDropdownData((prevState) => ({
						...prevState,
						[name]: res.data.data
					}));
				})
				.catch((err) => console.log(err));
		},
		[dropdownValue]
	);

	const handleSubmitClick = () => {
		validate();
		shouldSubmit.current = true;
	};

	return (
		<Dialog
			className='create-block-demo'
			open={open}
			onClose={() => closeModal("cancel")}
		>
			<h2>
				{mode === "multiple"
					? getTranslation("Multiple blocks", t)
					: getTranslation((mode === "edit" ? "Edit" : "Create") + " Block", t)}
			</h2>
			<InformationSection
				payload={payload}
				errors={errors}
				handleChangeDateTime={handleChangeDateTime}
				handleChangeCheckbox={handleChangeCheckbox}
				className={mode === "create" ? "order-0" : "order-1"}
			/>
			<VehicleSection
				payload={payload}
				dropdownValue={dropdownValue}
				errors={errors}
				handleChangeDropdown={handleDropdown}
				className={mode === "create" ? "order-1" : "order-0"}
				mode={mode}
				selectedMultiple={selectedMultiple}
				dropdownData={dropdownData}
				getDropdownData={getDropdownData}
			/>
			<ReasonSection
				payload={payload}
				errors={errors}
				handleChangeDropdown={handleChangeDropdown}
				className='order-2'
			/>
			<div className='btn-group order-3'>
				<Button
					onClick={() => closeModal("cancel")}
					type='secondary'
					children={getTranslation("Cancel", t)}
				/>
				<Button
					onClick={handleSubmitClick}
					type='primary'
					children={getTranslation("Accept", t)}
				/>
			</div>
		</Dialog>
	);
};

export default CreateBlockDemoModal;
