import React, { useEffect } from "react";
import "./blockCalendarFilters.css";
import Button from "../../../Button/Button";
import Dropdown from "../../../Dropdown/Dropdown";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getCitiesList,
  getShowroomList,
  loadBrandList
} from "../../../../redux-sagas/actions";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import statusFilter from "../../../../db/statusFilter";

const BlockCalendarFilters = ({ filters, setFilters }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const brandList = useSelector((state) => state.brandList);
  const citiesList = useSelector((state) => state.citiesList);
  const showroomList = useSelector((state) => state.showroomList);

  useEffect(() => { });

  const handleFiltersChange = (e) => {
    const { value, name } = e.target;
    let nameValue = "";
    switch (name) {
      case "showroom":
        nameValue = showroomList.data.find(
          (e) => e.showroomId === value
        ).showroomName;
        setFilters({ ...filters, [name]: { id: value, name: nameValue } });
        break;
      case "location":
        nameValue = citiesList.data.find((e) => e.id === value).name;
        setFilters({ ...filters, [name]: { id: value, name: nameValue } });
        break;
      case "brand":
        nameValue = brandList.data.find((e) => e.id === value).name;
        setFilters({ ...filters, [name]: { id: value, name: nameValue } });
        break;
      case "state":
        const val = value === getTranslation("Active", t) ? true : false;
        setFilters({ ...filters, [name]: { name: value, value: val } });
        break;
      default:
        break;
    }
  };

  const handleClear = () => {
    setFilters({
      showroom: { id: "", name: "" },
      location: { id: "", name: "" },
      brand: { id: "", name: "" },
      state: { name: "", value: "" }
    });
  };

  const handleOnOpen = (e, name) => {
    switch (name) {
      case "brand":
        dispatch(loadBrandList(""));
        break;
      case "showroom":
        dispatch(getShowroomList(""));
        break;
      case "location":
        dispatch(getCitiesList(""));
        break;
      default:
        break;
    }
  };

  const getId = (name, list) => {
    const filteredData = list.data.filter((d) => d.name === name);
    return filteredData.length ? filteredData[0].id : "";
  };

  return (
    <div className="block-filters-container">
      <Grid container className="justify-content-between">
        <Grid item xs={12} sm={8} md={5} lg={2}>
          <Dropdown
            label="Showroom"
            data={showroomList.data}
            valueField="showroomId"
            textField="showroomName"
            value={filters.showroom.id}
            name="showroom"
            handleChange={handleFiltersChange}
            onOpen={(e) => handleOnOpen(e, "showroom")}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          <Dropdown
            label={getTranslation("Location", t)}
            data={citiesList.data}
            valueField="id"
            textField="name"
            value={filters.location.id}
            name="location"
            handleChange={handleFiltersChange}
            onOpen={(e) => handleOnOpen(e, "location")}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          <Dropdown
            label={getTranslation("Brand", t)}
            data={brandList.data}
            valueField="id"
            textField="name"
            value={filters.brand.id}
            name="brand"
            handleChange={handleFiltersChange}
            onOpen={(e) => handleOnOpen(e, "brand")}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2}>
          <Dropdown
            label={getTranslation("Status", t)}
            data={statusFilter.map((item) => ({
              ...item,
              name: getTranslation(item.name, t)
            }))}
            valueField="name"
            textField="name"
            value={filters.state.name}
            name="state"
            handleChange={handleFiltersChange}
            onOpen={(e) => handleOnOpen(e, "state")}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={5} lg={2} sx={{ marginTop: 1 }}>
          <Button
            type="secondary"
            children={getTranslation("Clean", t)}
            onClick={handleClear}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BlockCalendarFilters;
