import React from "react";
import "./durationSection.css";
import Dropdown from "../../../Dropdown/Dropdown";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const DurationSection = ({ payload, dropdownData, getDropdownData, handleInputChange, errors }) => {
	const { t } = useTranslation();

	const handleOpenDropdown = (name) => getDropdownData(name);

	return (
		<div className='duration-box'>
			<h4>{getTranslation("Duration of Test Drive", t)}</h4>
			<Dropdown
				className='col-lg-6 ps-0 pe-0 pe-lg-4'
				label={getTranslation("Duration", t)}
				data={dropdownData.defaultDurationMinutes}
				value={payload.defaultDurationMinutes}
				handleChange={handleInputChange}
				onOpen={() => handleOpenDropdown("testdrivedurations")}
				name='defaultDurationMinutes'
				textField='name'
				valueField='value'
				error={errors.defaultDurationMinutes}
			/>
		</div>
	);
};

export default DurationSection;
