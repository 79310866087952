import { Dialog } from "@mui/material";
import "./blockCalendarModal.css";
import Button from "../../../Button/Button";
import BlockCancelInformation from "./BlockCancelInformation/BlockCancelInformation";
import BlockScheduleInformation from "./BlockScheduleInformation/BlockScheduleInformation";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const BlockCalendarModal = ({
	errors,
	filters,
	mode,
	closeModal,
	open,
	eventProps,
	payload,
	onPayloadChange,
	onDateTimeChange,
	onCheckboxChange
}) => {
	const { t } = useTranslation();
	return (
		<Dialog
			className='block-calendar-modal'
			open={open}
			onClose={closeModal}
		>
			{mode === "cancel" ? (
				<>
					<h2>{getTranslation("Cancel appointment", t)}</h2>
					<BlockCancelInformation eventProps={eventProps} />
				</>
			) : (
				<>
					<div className='header-text'>
						<h2>{getTranslation("Create block", t)}</h2>
					</div>
					<div>
						<BlockScheduleInformation
							filters={filters}
							eventProps={eventProps}
							payload={payload}
							errors={errors}
							onPayloadChange={onPayloadChange}
							onDateTimeChange={onDateTimeChange}
							onCheckboxChange={onCheckboxChange}
						/>
					</div>
				</>
			)}
			<div className='btns order-3'>
				<Button
					onClick={() => closeModal("cancel")}
					type='secondary'
				>
					{getTranslation("Cancel", t)}
				</Button>
				<Button
					onClick={() => closeModal("accept")}
					type='primary'
				>
					{getTranslation("Accept", t)}
				</Button>
			</div>
		</Dialog>
	);
};

export default BlockCalendarModal;
