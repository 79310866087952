import { IoPersonOutline } from "react-icons/io5";
import {
  getHeaderClass,
  getHeaderIcon,
  getHeaderTitle
} from "../../utils/getHeaderDetails";
import { useLocation } from "react-router-dom";
import "./header.css";
import { useSelector } from "react-redux";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Header = () => {
  let location = useLocation();
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  return (
    <div className="header">
      <div className={"details " + getHeaderClass(location.pathname)}>
        {userInfo && getHeaderIcon(location.pathname)}
        <h2>
          {userInfo && getTranslation(getHeaderTitle(location.pathname), t)}
        </h2>
      </div>
      <div className="profile">
        <div>
          <span className="name">{`${userInfo.firstname ?? ""} ${
            userInfo.lastname ?? ""
          }`}</span>
          <p>{getTranslation(userInfo?.role, t)}</p>
        </div>
        <IoPersonOutline />
      </div>
    </div>
  );
};

export default Header;
