import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_USER_INFO, GET_USER_MENU } from "../actions/types";
import { setUserInfo, setUserMenu } from "../actions";
import { getUserInfoApi, getUserMenuApi } from "../api/handler";

function* handleGetUserInfo() {
  try {
    const response = yield call(getUserInfoApi);
    if (response.status === 200) {
      localStorage.setItem("lng", response?.data?.data?.defaultLanguage ?? "es");
      yield put(setUserInfo(response.data.data));
    }
  } catch (err) {
    console.warn(err);
  }
}

function* handleGetUserMenu() {
    try {
      const response = yield call(getUserMenuApi);
      if (response.status === 200) {
        yield put(setUserMenu(response.data.data));
      }
    } catch (err) {
      console.warn(err);
    }
  }

export default function* showroomSaga() {
  yield all([
    takeLatest(GET_USER_INFO, handleGetUserInfo),
    takeLatest(GET_USER_MENU, handleGetUserMenu)
  ]);
}
