import { Switch } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./dayRow.css";
import { getTranslation } from "../../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const DayRow = ({
  day,
  payload,
  handleSwitchChange,
  handleTimepickerChange,
  errors
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`day-row ${
        errors[day.day + "StartTime"] || errors[day.day + "EndTime"]
          ? "align-items-center"
          : "align-items-end"
      }`}
    >
      <div className="day-active">
        <span>{day.display}</span>
        <Switch
          name={day.day + "Enabled"}
          onChange={handleSwitchChange}
          checked={payload[day.day + "Enabled"] ?? false}
        />
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="hours">
          <div className={errors[day.day + "StartTime"] ? "error" : ""}>
            <TimePicker
              className="time-picker-input"
              value={payload[day.day + "StartTime"] ?? null}
              onChange={(newValue) =>
                handleTimepickerChange(newValue, day.day + "StartTime")
              }
              label={getTranslation("Start time", t)}
            />
            {errors[day.day + "StartTime"] && (
              <span className="error">
              {getTranslation("Fill in this field correctly.", t)}
              </span>
            )}
          </div>
          <div className={errors[day.day + "EndTime"] ? "error" : ""}>
            <TimePicker
              className="time-picker-input"
              value={payload[day.day + "EndTime"] ?? null}
              onChange={(newValue) =>
                handleTimepickerChange(newValue, day.day + "EndTime")
              }
              label={getTranslation("End time", t)}
            />
            {errors[day.day + "StartTime"] && (
              <span className="error">
                {getTranslation("Fill in this field correctly.", t)}
              </span>
            )}
          </div>
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default DayRow;
