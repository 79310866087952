import Dropdown from "../../../components/Dropdown/Dropdown";
import Button from "../../../components/Button/Button";
import statusFilter from "../../../db/statusFilter";
import { useState } from "react";
import "./showroomFilters.css";
import fetchClient from "../../../services/fetch";
import { getTranslation } from "../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const ShowroomFilters = ({
  filters,
  handleFiltersChange,
  handleSearchFilters,
  handleClearFilters,
  resetPageToZero
}) => {
  const { t } = useTranslation();
  const [dropdownData, setDropdownData] = useState({
    showrooms: [],
    cities: []
  });

  const handleChange = (e) => {
    handleFiltersChange({ ...filters, [e.target.name]: e.target.value });
    resetPageToZero();
  };

  const handleOpenDropdown = (name) => {
    let url = `/commondata/${name}`;

    if (name === 'showrooms') {
      url += '?listAll=true';
    }

    fetchClient()
      .get(url)
      .then((res) => setDropdownData({ ...dropdownData, [name]: res.data.data }));
  };

  return (
    <div className="filters">
      <span>{getTranslation("Select filter", t)}</span>
      <div className="row">
        <Dropdown
          value={filters.showroomName}
          className="col-xl-3 ps-0 pe-sm-0 pe-xl-5"
          data={dropdownData.showrooms}
          handleChange={handleChange}
          name="showroomName"
          label="Showroom"
          onOpen={() => handleOpenDropdown("showrooms")}
          valueField="name"
          textField="name"
        />
        <Dropdown
          value={filters.cityName}
          className="col-xl-3 ps-0 pe-sm-0 pe-xl-5"
          data={dropdownData.cities}
          handleChange={handleChange}
          name="cityName"
          label={getTranslation("City", t)}
          onOpen={() => handleOpenDropdown("cities")}
          valueField="name"
          textField="name"
        />
        <Dropdown
          data={statusFilter.map((item) => ({ ...item, name: getTranslation(item.name, t) }))}
          value={filters.isActive}
          handleChange={handleChange}
          className="col-xl-3 ps-0 pe-xl-5 pe-sm-0 mb-4 mb-xl-0"
          name="isActive"
          label={getTranslation("Status", t)}
          valueField="value"
          textField="name"
        />
        <div className="btns col-xl-3 px-0 d-flex flex-wrap flex-xxl-nowrap justify-content-xl-between">
          <Button
            onClick={() => handleClearFilters({})}
            type="secondary me-2 me-xl-0"
          >
            {getTranslation("Clean", t)}
          </Button>
          <Button
            onClick={handleSearchFilters}
            type="primary"
          >
            {getTranslation("Search", t)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ShowroomFilters;
