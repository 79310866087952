import { FormControlLabel, Switch } from "@mui/material";
import "./statusSection.css";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const StatusSection = ({ payload, handleSwitchChange }) => {
  const { t } = useTranslation();

  return (
    <div className="status">
      <h3>{getTranslation("Showroom status", t)}</h3>
      <span>{getTranslation("Select showroom status", t)}</span>
      <FormControlLabel
        control={
          <Switch
            name="isActive"
            onChange={handleSwitchChange}
            checked={payload?.isActive ?? true}
          />
        }
        label={getTranslation("Active", t)}
      />
    </div>
  );
};

export default StatusSection;
