import axios from "axios";
import Cookie from "./Cookies";
import store from "../redux-sagas/store";
import { SET_AUTH } from "../redux-sagas/actions/types";

const BASE_URL = `${window.location.origin}/api/v1`;

const fetchClient = () => {
  const client = axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get("tdb_token")}`
    }
  });

  // Intercept response
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      switch (error.response.status) {
        case 401:
          refreshToken()
            .post(`${BASE_URL}/auth/refresh`)
            .then((res) => {
              Cookie.set("tdb_token", res.data.data.token);
              Cookie.set("tdb_rftoken", res.data.data.refreshToken);

              error.config.headers[
                "Authorization"
              ] = `Bearer ${res.data.data.token}`;

              return axios.request(error.config);
            })
            .catch((err) => {
              if (err.response.status === 401) {
                store.dispatch({ type: SET_AUTH, action: false})
                Cookie.remove("tdb_token");
                Cookie.remove("tdb_rftoken");
                window.location.href = `${window.location.origin}/invalid`;
              }
            });
          break;
        default: break;
      }

      return Promise.reject(error);
    }
  );

  return client;
};

export const refreshToken = () => {
  return axios.create({
    baseURL: BASE_URL,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get("tdb_rftoken")}`
    }
  })
}

export default fetchClient;
