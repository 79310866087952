import { formatDate } from "./formatDateTime";

export const getBlockFilterParams = (filters) => {
	let filterQuery = "";
	let toAppendFilterQueries = "";
	for (const key in filters) {
		if (key === "vin") {
			filterQuery += JSON.stringify({
				field: key,
				condition: "contains",
				value: filters[key]
			});
		} else {
			toAppendFilterQueries += `&${key}=${
				key.includes("Date") ? formatDate(filters[key]) : filters[key]
			}`;
		}
	}

	return { filterQuery, toAppendFilterQueries };
};

export const getBlockSortParams = (sort) => {
	let sortQuery = sort;
	const isDateTimeField = /Date|Time/.test(sort.field);
	if (sort && isDateTimeField) sortQuery = { ...sort, field: sort.field.replace("block", "start") };

	return JSON.stringify(sortQuery);
};
