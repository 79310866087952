import { useSelector } from "react-redux";
import { useState } from "react";
import moment from "moment";
import BlockCalendar from "../../components/Calendar/BlockCalendar/BlockCalendar";
import TestDriveCalendar from "../../components/Calendar/TestDriveCalendar/TestDriveCalendar";
import getStartEndDates from "../../utils/getStartEndDates";

const ManageCalendar = () => {
  const currentRole = useSelector((state) => state.userInfo);
  // Set initial value to dates of the week (Sunday - Saturday)
  const { from, to } = getStartEndDates(new Date(), "week");
  const [calendarParams, setCalendarParams] = useState({
    search: "",
    fromDate: from,
    toDate: to,
    currentDate: new Date()
  });

  const renderCalendar = (role) => {
    switch (role) {
      case 5:
      case 4:
      case 3:
        return (
          <BlockCalendar
            role={role}
            calendarParams={calendarParams}
            setCalendarParams={setCalendarParams}
          />
        );
      case 2:
      case 1:
        return (
          <TestDriveCalendar
            role={role}
            calendarParams={calendarParams}
            setCalendarParams={setCalendarParams}
          />
        );
      default:
        return null;
    }
  };

  return <div>{renderCalendar(currentRole.accessLevel)}</div>;
};

export default ManageCalendar;
