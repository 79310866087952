import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_BLOCK_DEMO_LIST } from "../actions/types";
import { setBlockDemoList, setLoading } from "../actions";
import { getBlockDemoListApi } from "../api/handler";

function* handleGetBlockDemoList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getBlockDemoListApi, args.params);
    if (response.status === 200) {
      yield put(setBlockDemoList(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
  yield put(setLoading(false));
}

export default function* blockDemo() {
  yield all([takeLatest(GET_BLOCK_DEMO_LIST, handleGetBlockDemoList)]);
}
