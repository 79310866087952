import { takeLatest, all, call } from "redux-saga/effects";
import { BLOCK_VEHICLE } from "../actions/types";
import { blockVehicleApi } from "../api/handler";

function* hanldeBlockVehicle({ blockData }) {
  try {
    const response = yield call(blockVehicleApi, blockData);
    if (response.status === 204) {
      console.log("Blocked vehicle");
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* blockSaga() {
  yield all([takeLatest(BLOCK_VEHICLE, hanldeBlockVehicle)]);
}
