import { OverlayTrigger, Popover } from "react-bootstrap";
import { createPortal } from "react-dom";
import Button from "../../../Button/Button";
import "./overlayInsert.css";
import moment from "moment";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const OverlayInsert = ({ buttonRef, selected, el, openModal }) => {
  const { t } = useTranslation();
  const handleClick = () => openModal("create");

  const popoverTop = (
    <Popover className="block" id="popover-positioned-top" title={getTranslation("Create block", t)}>
      <div>
        <h3>{getTranslation("Create block", t)}</h3>
        <span>
          <label>{getTranslation("Blocking time", t)}:</label>{" "}
          {moment(selected.start).format("hh:mm")} -{" "}
          {moment(selected.end).format("hh:mm A")}
        </span>
        <span>
          <label>{getTranslation("Blocking time", t)}:</label>{" "}
          {moment(selected.start).format("YYYY/MM/DD")}
        </span>
      </div>
      <Button onClick={handleClick} type="calendar">
        {getTranslation("Block action", t)}
      </Button>
    </Popover>
  );

  const overlay = (
    <OverlayTrigger
      rootClose
      trigger="click"
      placement="top"
      overlay={popoverTop}
    >
      <div ref={buttonRef} className="custom-slot"></div>
    </OverlayTrigger>
  );

  return createPortal(overlay, el.current);
};

export default OverlayInsert;
