import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_BRAND_LIST } from "../actions/types";
import { getCommonBrandListApi } from "../api/handler";
import { setBrandList } from "../actions";

function* handleLoadBrandList(args) {
  try {
    const response = yield call(getCommonBrandListApi, args.params);
    if (response.status === 200) {
      yield put(setBrandList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* brandSaga() {
  yield all([takeLatest(GET_BRAND_LIST, handleLoadBrandList)]);
}
