import TextInput from "../../../TextInput/TextInput";
import "./blockCalendarSearch.css";
import { IoClose } from "react-icons/io5";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const BlockCalendarSearch = ({ calendarParams, setCalendarParams, role }) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    setCalendarParams({ ...calendarParams, search: e.target.value });
  };

  const handleClickClear = () => {
    setCalendarParams({ ...calendarParams, search: "" });
  };

  return (
    <div className="calendar-filter d-flex align-items-end justify-content-between">
      <div className="d-flex align-items-end gap-3">
        <TextInput
          label="Showroom"
          name="document"
          handleChange={handleChange}
          value={calendarParams.search}
        />
        <div className="close-icon-box">
          <IoClose className="close-icon" onClick={handleClickClear} />
        </div>
      </div>
      <div className="legend-group d-flex align-items-end">
        <div className="d-flex gap-2 align-items-center">
          <div className="legend-1"></div>
          <span>{getTranslation("With availability", t)}</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="legend-2"></div>
          <span>{getTranslation("Block", t)}</span>
        </div>
        <div className="d-flex gap-2 align-items-center">
          <div className="legend-3"></div>
          <span>{getTranslation("Test Drive", t)}</span>
        </div>
      </div>
    </div>
  );
};

export default BlockCalendarSearch;
