import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@mui/material";
import TextInput from "../../../../TextInput/TextInput";
import "./testDriveScheduleInformation.css";
import Dropdown from "../../../../Dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { loadDocumentTypeList } from "../../../../../redux-sagas/actions";
import { getTranslation } from "../../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import useValidateForm from "../../../../../hooks/useValidateForm";

const TestDriveScheduleInformation = ({
  filters,
  vehicleInfo,
  payload,
  setPayload,
  toggleDisclaimerModal,
  radioBoxValue,
  errors,
  handleRadioBoxChange,
  brandSettings
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userInfo = useSelector((state) => state.userInfo);
  const documentTypeList = useSelector((state) => state.documentTypeList).data;
  const { termsAndConditionsUrl, dataProtectionPolicyUrl } = brandSettings || {};
  const { validateOnChange } = useValidateForm();

  const isTermsAndConditionsUrl = brandSettings && termsAndConditionsUrl;
  const isDataPolicyUrl = brandSettings && dataProtectionPolicyUrl;

  const handleOpen = (e) => {
    dispatch(loadDocumentTypeList());
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    const obj = validateOnChange(name, value, payload);
    if (obj.isValid) setPayload(obj.newPayload);

    if (name === "documentTypeId") setPayload((prevPayload) => ({ ...prevPayload, documentNumber: "" }))
  };

  return (
    <div className="test-drive-schedule-information container p-0">
      <div className="reservation-data">
        <h5>{getTranslation("Reservation information", t)}</h5>
        <div className="row row-cols-3">
          {userInfo.accessLevel === 1 && (
            <div className="col-lg-6 ps-0 pe-0 mb-3">
              <span>
                <label className="label-header">
                  {getTranslation("Advisor", t)}:{" "}
                  <span className="label-text">{`${userInfo?.firstname} ${userInfo?.lastname}`}</span>
                </label>
              </span>
            </div>
          )}
          <div
            className={
              "col-lg-6 pe-0 ps-0 mb-3 " +
              (userInfo.accessLevel === 2 ? "order-2" : "")
            }
          >
            <span>
              <label className="label-header">
                {userInfo.accessLevel === 2
                  ? "Showroom"
                  : getTranslation("Location", t)}
                : <span className="label-text">{vehicleInfo.showroomName}</span>
              </label>
            </span>
          </div>
          <div
            className={
              "col-lg-6 ps-0 pe-0 mb-3 " +
              (userInfo.accessLevel === 2 ? "order-1" : "")
            }
          >
            <span>
              <label className="label-header">
                {getTranslation("Brand", t)}:{" "}
                <span className="label-text">{filters.brand.name}</span>
              </label>
            </span>
          </div>
          <div
            className={
              "col-lg-6 ps-0 pe-0 mb-3 " +
              (userInfo.accessLevel === 2 ? "order-3" : "")
            }
          >
            <span>
              <label className="label-header">
                {getTranslation("Model", t)}:{" "}
                <span className="label-text">{filters.model.name}</span>
              </label>
            </span>
          </div>
          <div
            className={
              "col-lg-6 ps-0 pe-0 mb-3 " +
              (userInfo.accessLevel === 2 ? "order-4" : "")
            }
          >
            <span>
              <label className="label-header">
                {getTranslation("Variant", t)}:{" "}
                <span className="label-text">{filters.variant.name}</span>
              </label>
            </span>
          </div>
          {userInfo.accessLevel === 1 && (
            <div className="col-lg-6 pe-0 ps-0 mb-3">
              <span>
                <label className="label-header">
                  {getTranslation("Fuel type", t)}:{" "}
                  <span className="label-text">
                    {getTranslation(filters.fuelType.name, t)}
                  </span>
                </label>
              </span>
            </div>
          )}
        </div>
      </div>
      <div className="reservation-data">
        <h5>{getTranslation("Customer information", t)}</h5>
        <div className="row">
          <TextInput
            className="col-lg-6 ps-0 pe-0 pe-lg-4 mb-4"
            label={getTranslation("Name", t)}
            name="firstname"
            value={payload.firstname}
            error={errors.firstname}
            handleChange={handleChange}
          />
          <TextInput
            className="col-lg-6 pe-0 ps-0 ps-lg-4 mb-4"
            label={getTranslation("Surname", t)}
            name="lastname"
            value={payload.lastname}
            error={errors.lastname}
            handleChange={handleChange}
          />
          <Dropdown
            className="col-lg-6 ps-0 pe-0 pe-lg-4 mb-4"
            label={getTranslation("Document type", t)}
            name="documentTypeId"
            data={documentTypeList.map((item) => ({
              ...item,
              name: getTranslation(item.name, t)
            }))}
            value={payload.documentTypeId}
            error={errors.documentTypeId}
            textField="name"
            valueField="id"
            onOpen={handleOpen}
            handleChange={handleChange}
          />
          <TextInput
            className="col-lg-6 pe-0 ps-0 ps-lg-4 mb-4"
            label={getTranslation("Document number", t)}
            name="documentNumber"
            value={payload.documentNumber}
            error={errors.documentNumber}
            handleChange={handleChange}
          />
          <TextInput
            className="col-lg-6 ps-0 pe-0 pe-lg-4 mb-4 mb-lg-0"
            label={getTranslation("Email", t)}
            name="email"
            value={payload.email}
            error={errors.email}
            handleChange={handleChange}
          />
          <TextInput
            className="col-lg-6 pe-0 ps-0 ps-lg-4"
            label={getTranslation("Cellphone", t)}
            name="phoneNumber"
            value={payload.phoneNumber}
            error={errors.phoneNumber}
            handleChange={handleChange}
          />
        </div>
      </div>
      <div className="terms-condition">
        <div className="row align-items-end">
          <div className="col-lg-8 ps-0 pe-0 mb-4">
            <span>
              <label>
                {getTranslation("I agree", t)}{" "}
                {
                  isTermsAndConditionsUrl
                    ? (<a className="terms-condition-text" href={brandSettings.termsAndConditionsUrl} target={"_blank"} rel="noreferrer">{getTranslation(" to the terms and conditions", t)}</a>)
                    : (<p className="terms-condition-text" onClick={() => toggleDisclaimerModal("terms")}>{getTranslation(" to the terms and conditions", t)}</p>)
                }
              </label>
            </span>
          </div>
          <div className="col-lg-4 pe-0 ps-4 mb-4">
            <FormControl>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={radioBoxValue.terms}
                onChange={handleRadioBoxChange}
              >
                <FormControlLabel
                  value="yes"
                  name="terms"
                  control={<Radio />}
                  label={getTranslation("Yes", t)}
                />
                <FormControlLabel
                  value="no"
                  name="terms"
                  control={<Radio />}
                  label={getTranslation("No", t)}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="col-lg-8 ps-0 pe-0 mb-4">
            <span>
              <label>
                {getTranslation("I declare that I know and understand the", t)}{" "}
                {
                  isDataPolicyUrl
                    ? (<a className="terms-condition-text" href={brandSettings.dataProtectionPolicyuRL} target={"_blank"} rel="noreferrer">{getTranslation("Personal Data Protection Policy", t)}</a>)
                    : (<p className="terms-condition-text" onClick={() => toggleDisclaimerModal("data-policy")}>{getTranslation("Personal Data Protection Policy", t)}</p>)
                }{" "}
                {getTranslation(
                  "and I expressly authorize the processing of my personal data",
                  t
                )}
              </label>
            </span>
          </div>
          <div className="col-lg-4 pe-0 ps-4 mb-4">
            <FormControl>
              <RadioGroup
                row
                name="row-radio-buttons-group"
                value={radioBoxValue.dataPrivacy}
                onChange={handleRadioBoxChange}
              >
                <FormControlLabel
                  value="yes"
                  name="dataPrivacy"
                  control={<Radio />}
                  label={getTranslation("Yes", t)}
                />
                <FormControlLabel
                  value="no"
                  name="dataPrivacy"
                  control={<Radio />}
                  label={getTranslation("No", t)}
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestDriveScheduleInformation;
