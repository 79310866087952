import { useSelector } from "react-redux";
import BlockShowroom from "./BlockShowroom/BlockShowroom";
import BlockDemo from "./BlockDemo/BlockDemo";

const ManageBlocks = () => {
  const role = useSelector((state) => state.userInfo?.accessLevel);

  return <>{role === 4 ? <BlockDemo /> : <BlockShowroom />}</>;
};

export default ManageBlocks;
