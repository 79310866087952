import React from "react";
import "./notification.css";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toggleNotification } from "../../redux-sagas/actions";
import {
  MdClose,
  MdOutlineCheckCircleOutline,
  MdOutlineErrorOutline
} from "react-icons/md";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const Notification = () => {
  const { isOpen, content1, content2, highlight1, highlight2, type } =
    useSelector((state) => state.notification);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const closeNotif = () => {
    dispatch(
      toggleNotification({
        isOpen: false,
        type,
        content1,
        content2,
        highlight1,
        highlight2
      })
    );
  };

  return (
    <Snackbar
      autoHideDuration={5000}
      open={isOpen}
      onClose={closeNotif}
      className={`${type === "success" ? "success" : "error"} notification`}
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          {type === "success" ? (
            <MdOutlineCheckCircleOutline />
          ) : (
            <MdOutlineErrorOutline />
          )}
          <div className="content">
            <span>
              <strong>
                {type === "success"
                  ? `${getTranslation("done", t)}: `
                  : `${getTranslation("error", t)}: `}
              </strong>
            </span>
            <span> {content1} </span>
            <span>
              <strong>{highlight1}</strong>
            </span>
            <span> {content2} </span>
            <span>
              <strong>{highlight2}</strong>
            </span>
          </div>
        </div>
        <MdClose onClick={closeNotif} className="close-btn" />
      </div>
    </Snackbar>
  );
};

export default Notification;
