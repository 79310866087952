import {
  format,
  getYear,
  getMonth,
  getDate,
  isSameDay,
  differenceInDays,
  addDays
} from "date-fns";
import dayjs from "dayjs";
import { getTranslation } from "./getTranslation";
import i18next from "i18next";
import moment from "moment";

export const formatTime = (time) => format(new Date(time), "HH:mm:ss");

export const formatDate = (date) => format(new Date(date), "yyyy-MM-dd");

export const createNewDate = (time) => {
  const today = new Date();
  const timeSplit = time.split(":");

  return new Date(
    getYear(today),
    getMonth(today),
    getDate(today),
    timeSplit[0],
    timeSplit[1],
    timeSplit[2]
  );
};

export const formatMonthTranslation = (date, t) => {
  const lng = i18next.language;

  const day = format(new Date(date), "dd");
  const month = getTranslation(format(new Date(date), "MMMM"), t);

  if (lng === "es") {
    return `${day} de ${month}`;
  } else {
    return `${month} ${day}`;
  }
};

export const createNewDates = (obj) => {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (key.includes("Time") && newObj[key]) {
      const today = new Date();
      const times = newObj[key].split(":");
      newObj[key] = dayjs(
        new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          times[0],
          times[1],
          times[2]
        )
      );
    } else if (key.includes("Date") && newObj[key]) {
      newObj[key] = dayjs(newObj[key]);
    }
  }

  return newObj;
};

export const formatObjTime = (obj) => {
  const newObj = { ...obj };
  for (const key in newObj) {
    if (key.includes("Time") && newObj[key]) {
      newObj[key] = formatTime(newObj[key]);
    }
  }

  return newObj;
};

export const formatEvents = (events) => {
  const newEvents = [];
  events.forEach((event) => {
    const { eventStartDate, eventEndDate, endTime } = event;

    if (!isSameDay(new Date(eventStartDate), new Date(eventEndDate))) {
      // get number of days between dates
      const days =
        differenceInDays(new Date(eventEndDate), new Date(eventStartDate)) + 1;

      // create an event object for each day
      // where the start and end dates are the same
      for (let i = 0; i < days; i++) {
        const endTimeSplit = endTime.split(":").map((item) => Number(item));
        const newStartDate = addDays(new Date(eventStartDate), i);
        const newEndDate = addDays(new Date(eventStartDate), i);
        newEndDate.setHours(endTimeSplit[0]);
        newEndDate.setMinutes(endTimeSplit[1]);
        newEndDate.setSeconds(endTimeSplit[2]);

        newEvents.push({
          ...event,
          eventStartDate: newStartDate,
          eventEndDate: newEndDate
        });
      }
    } else
      newEvents.push({
        ...event,
        eventStartDate: new Date(eventStartDate),
        eventEndDate: new Date(eventEndDate)
      });
  });

  return newEvents;
};

export const formatDateForEvents = (start, end) => {
  return `${moment(start).format("hh:mm")} - ${moment(end).format("hh:mm A")}`
}