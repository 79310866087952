import { useTranslation } from "react-i18next";
import { getTranslation } from "../../../../../utils/getTranslation";
import "./testDriveCancelInformation.css";
import moment from "moment";
import i18next from "i18next";

const TestDriveCancelInformation = ({ eventProps, role }) => {
  const { t } = useTranslation();
  const lng = i18next.language;

  return (
    <div className="test-drive-information-container">
      <div>
        <span>
          {role === 1 ? (
            <span>
              {getTranslation("Are you sure to cancel the", t)}{" "}
              <strong>{getTranslation("Test Drive", t)}</strong>{" "}
              {getTranslation("scheduled for", t)}{" "}
            </span>
          ) : (
            <span>
              {getTranslation(
                "Are you sure to cancel the appointment scheduled for",
                t
              )}{" "}
            </span>
          )}
        </span>
        <span className="date-text">
          {`${getTranslation(
            moment(eventProps.eventStartDate).format("dddd"),
            t
          )} ${moment(eventProps.eventStartDate).format("DD")} ${
            lng === "es" ? "de" : ""
          } ${moment(eventProps.eventStartDate).format("h:mm")} - ${moment(
            eventProps.eventEndDate
          ).format("h:mm a")}`}
        </span>
        <span>, {getTranslation("definitely", t)}?</span>
      </div>
    </div>
  );
};

export default TestDriveCancelInformation;
