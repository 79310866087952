import React, { useCallback, useEffect, useRef, useState } from "react";
import "./blockDemoCalendar.css";
import "react-big-calendar-like-google/lib/css/react-big-calendar.css";
import BigCalendar from "react-big-calendar-like-google";
import moment from "moment";
import {
  createNewDate,
  formatDate,
  formatEvents
} from "../../../../utils/formatDateTime";
import CustomEvent from "../../../../components/Calendar/CustomEvent/CustomEvent";
import CustomToolbar from "../../../../components/Calendar/CustomToolbar/CustomToolbar";
import fetchClient from "../../../../services/fetch";
import OverlayInsert from "../../../../components/Calendar/BlockShowroomCalendar/OverlayInsert/OverlayInsert";
import getStartEndDates from "../../../../utils/getStartEndDates";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

BigCalendar.momentLocalizer(moment);
const BlockDemoCalendar = ({
  isCreateModalOpen,
  openModal,
  calendarSlot,
  setCalendarSlot,
  vin
}) => {
  const { t } = useTranslation();
  const el = useRef(null);
  const view = useRef("week");
  const buttonRef = useRef();
  const [clicked, setClicked] = useState(null);
  const [events, setEvents] = useState([]);
  const [dates, setDates] = useState({
    current: new Date(),
    from: getStartEndDates(new Date(), "week").from,
    to: getStartEndDates(new Date(), "week").to
  });

  const fetchEvents = useCallback(() =>
    fetchClient()
      .get(
        `/blockedvehicles/calendar?vin=${vin}&fromDate=${dates.from}&toDate=${dates.to}`
      )
      .then((res) => setEvents(formatEvents(res.data.data)))
      .catch((err) => console.log(err)), [dates, vin]);

  useEffect(() => {
    if (!isCreateModalOpen) {
      const getData = setTimeout(fetchEvents, 200);
      return () => clearTimeout(getData)
    }
  }, [vin, isCreateModalOpen, dates, fetchEvents]);

  const onNavigate = (date, view) => {
    const { from, to } = getStartEndDates(date, view);
    setDates({ current: date, from, to });
  };

  const calendarView = (props) => {
    view.current = props;
    onNavigate(dates.current, props);
  };

  const customWeekDayViewHeader = (props) =>
    getTranslation(moment(props.date).format("ddd"), t) +
    " " +
    moment(props.date).format("DD");

  const customMonthHeader = (props) => getTranslation(props.label, t);

  const handleClickSlot = (e) => {
    const today = new Date();
    if (
      (view.current === "week" || view.current === "day") &&
      e.start >= today
    ) {
      setCalendarSlot(e);
      el.current = document.getElementsByClassName(e.start.toISOString())[
        formatDate(e.start) === formatDate(new Date()) ? 1 : 0
      ];
      setClicked(el.current);
      setTimeout(() => buttonRef.current.click(), 100);
    }
  };

  return (
    <div className="scheduler">
      <BigCalendar
        events={events.map((item) => ({
          ...item,
          eventTitle: getTranslation(item.eventTitle, t)
        }))}
        selectable
        timeslots={1}
        slotPropGetter={(date) => {
          let className = date.toISOString();
          const today = new Date();
          if (today >= date) className += " disabled";
          return { className };
        }}
        startAccessor="eventStartDate"
        endAccessor="eventEndDate"
        titleAccessor="eventTitle"
        defaultDate={new Date()}
        onNavigate={(date, view) => onNavigate(date, view)}
        onView={calendarView}
        components={{
          toolbar: CustomToolbar,
          event: CustomEvent,
          week: {
            header: customWeekDayViewHeader
          },
          day: {
            header: customWeekDayViewHeader
          },
          month: {
            header: customMonthHeader
          }
        }}
        onSelectSlot={(e) => handleClickSlot(e)}
        defaultView="week"
        min={createNewDate("6:00:00")}
        max={createNewDate("18:30:00")}
        messages={{
          showMore: (total) => `+${total} ${getTranslation("more", t)}`
        }}
      />
      {clicked && (
        <OverlayInsert
          openModal={openModal}
          selected={calendarSlot}
          buttonRef={buttonRef}
          el={el}
        />
      )}
    </div>
  );
};

export default BlockDemoCalendar;
