import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_SHOWROOM_LIST } from "../actions/types";
import { setLoading, setShowroomList } from "../actions";
import { getShowroomListApi } from "../api/handler";

function* handleGetShowroomList(args) {
  yield put(setLoading(true));
  try {
    const response = yield call(getShowroomListApi, args.params);
    if (response.status === 200) {
      yield put(setShowroomList(response.data));
    }
  } catch (err) {
    console.warn(err);
  }
  yield put(setLoading(false));
}

export default function* showroomSaga() {
  yield all([takeLatest(GET_SHOWROOM_LIST, handleGetShowroomList)]);
}
