import { takeLatest, all, call, put } from "redux-saga/effects";
import { GET_FUEL_TYPE_LIST } from "../actions/types";
import { getCommonFuelTypeListApi } from "../api/handler";
import { setFuelTypeList } from "../actions";

function* handleLoadFuelTypeList({params}) {
  try {
    const response = yield call(getCommonFuelTypeListApi, params);
    if (response.status === 200) {
      yield put(setFuelTypeList(response.data));
    }
  } catch (e) {
    console.warn(e);
  }
}

export default function* fuelTypeSaga() {
  yield all([takeLatest(GET_FUEL_TYPE_LIST, handleLoadFuelTypeList)]);
}
