import { TextField } from "@mui/material";
import "./textInput.css";
import { getTranslation } from "../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const TextInput = ({
	value,
	handleChange,
	label,
	disabled,
	error,
	name,
	className = "",
	isRequired = false
}) => {
	const { t } = useTranslation();
	return (
		<div className={`text-input ${className} ${error ? "error" : ""}`}>
			<TextField
				required={isRequired}
				error={!value ? error : false}
				variant='standard'
				disabled={disabled | false}
				label={label}
				onChange={handleChange}
				name={name}
				value={value || ""}
			/>
			{error && <span className='error'>{getTranslation("Fill in this field correctly.", t)}</span>}
		</div>
	);
};

export default TextInput;
