import {
  DatePicker,
  LocalizationProvider,
  TimePicker
} from "@mui/x-date-pickers";
import { Checkbox, FormControlLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "./blockInformationSection.css";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/es";

const BlockInformationSection = ({
  className,
  handleChangeDateTime,
  handleChangeCheckbox,
  errors,
  payload
}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={className + " block-info"}>
      <h3 className="mb-3">{getTranslation("Blocking information", t)}</h3>
      <span>{getTranslation("Select date for blocking", t)}</span>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <div className="dates mb-3">
          <div className={errors.startDate ? "error" : ""}>
            <DatePicker
              className="calendar-input"
              value={payload.startDate ?? null}
              onChange={(newValue) =>
                handleChangeDateTime(newValue, "startDate")
              }
              label={getTranslation("Start Date", t)}
              minDate={dayjs(new Date())}
            />
            {errors.startDate && (
              <span className="error">
                {getTranslation("Fill in this field correctly.", t)}
              </span>
            )}
          </div>
          <div className={errors.endDate ? "error" : ""}>
            <DatePicker
              className="calendar-input"
              value={payload.endDate ?? null}
              onChange={(newValue) => handleChangeDateTime(newValue, "endDate")}
              label={getTranslation("End Date", t)}
              minDate={dayjs(new Date())}
            />
            {errors.endDate && (
              <span className="error">
                {getTranslation("Fill in this field correctly.", t)}
              </span>
            )}
          </div>
        </div>
      </LocalizationProvider>
      <span>{getTranslation("Select the time or time for blocking", t)}</span>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="hours">
          <div className={errors.startTime ? "error" : ""}>
            <TimePicker
              className="time-picker-input"
              disabled={payload.isAllDay}
              value={payload.startTime ?? null}
              onChange={(newValue) =>
                handleChangeDateTime(newValue, "startTime")
              }
              label={getTranslation("Start time", t)}
            />
            {errors.startTime && (
              <span className="error">
                {getTranslation("Fill in this field correctly.", t)}
              </span>
            )}
          </div>
          <div className={errors.endTime ? "error" : ""}>
            <TimePicker
              className="time-picker-input"
              disabled={payload.isAllDay}
              value={payload.endTime ?? null}
              onChange={(newValue) => handleChangeDateTime(newValue, "endTime")}
              label={getTranslation("End time", t)}
            />
            {errors.endTime && (
              <span className="error">
                {getTranslation("Fill in this field correctly.", t)}
              </span>
            )}
          </div>
          <FormControlLabel
            control={
              <Checkbox
                name="isAllDay"
                checked={payload.isAllDay ?? false}
                onChange={handleChangeCheckbox}
              />
            }
            label={getTranslation("Block all day", t)}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
};

export default BlockInformationSection;
