import React from "react";
import "./informationSection.css";
import Dropdown from "../../../Dropdown/Dropdown";
import TextInput from "../../../TextInput/TextInput";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";

const InformationSection = ({
	payload,
	handleInputChange,
	getDropdownData,
	dropdownData,
	errors,
	mode
}) => {
	const { t } = useTranslation();
	const isEditMode = mode === "edit";
	const handleOpenDropdown = (name) => getDropdownData(name);

	return (
		<div className='demo-vehicle-info'>
			<h4>{getTranslation("Vehicle information", t)}</h4>
			<div className='row'>
				<Dropdown
					handleChange={handleInputChange}
					onOpen={() => handleOpenDropdown("models")}
					data={dropdownData.models}
					value={payload.modelId}
					error={errors.modelId}
					disabled={isEditMode}
					textField='name'
					valueField='id'
					className='col-lg-6 ps-0 pe-0 pe-lg-4 mb-4'
					label={getTranslation("Model", t)}
					name='modelId'
				/>
				<Dropdown
					className='col-lg-6 pe-0 ps-0 ps-lg-4 mb-4'
					handleChange={handleInputChange}
					onOpen={() => handleOpenDropdown("brands")}
					value={payload.brandId}
					label={getTranslation("Brand", t)}
					disabled={isEditMode}
					name='brandId'
					textField='name'
					data={dropdownData.brands}
					valueField='id'
					error={errors.brandId}
				/>
				<Dropdown
					className='col-lg-6 ps-0 pe-0 pe-lg-4 mb-4'
					handleChange={handleInputChange}
					onOpen={() => handleOpenDropdown("variants")}
					value={payload.variantId}
					label={getTranslation("Variant", t)}
					disabled={isEditMode}
					name='variantId'
					textField='name'
					data={dropdownData.variants}
					valueField='id'
					error={errors.variantId}
				/>
				<Dropdown
					className='col-lg-6 pe-0 ps-0 ps-lg-4 mb-4'
					handleChange={handleInputChange}
					onOpen={() => handleOpenDropdown("fueltypes")}
					value={payload.fuelTypeId}
					label={getTranslation("Fuel type", t)}
					disabled={isEditMode}
					name='fuelTypeId'
					textField='name'
					data={dropdownData.fueltypes}
					valueField='id'
					error={errors.fuelTypeId}
				/>
				<TextInput
					className='col-lg-6 ps-0 pe-0 pe-lg-4 mb-4'
					handleChange={handleInputChange}
					value={payload.vin}
					disabled={isEditMode}
					label='VIN'
					name='vin'
					error={errors.vin}
				/>
				<Dropdown
					className='col-lg-6 pe-0 ps-0 ps-lg-4 mb-4'
					handleChange={handleInputChange}
					onOpen={() => handleOpenDropdown("showrooms")}
					value={payload.showroomId}
					label={getTranslation("Showroom", t)}
					name='showroomId'
					textField='name'
					data={dropdownData.showrooms}
					valueField='id'
					error={errors.showroomId}
				/>
				<TextInput
					className='col-lg-6 ps-0 pe-0 pe-lg-4 mb-4 mb-lg-0'
					handleChange={handleInputChange}
					value={payload.category}
					label={getTranslation("Category", t)}
					name='category'
					error={errors.category}
				/>
			</div>
		</div>
	);
};

export default InformationSection;
