const getUniqueValues = (value, index, array) => {
	if (typeof value === "string") {
		return array.indexOf(value) === index;
	} else {
		const arrayValuesInString = array.map((item) => JSON.stringify(item));

		return arrayValuesInString.indexOf(JSON.stringify(value)) === index;
	}
};

export default getUniqueValues;
