import { PiWrenchFill } from "react-icons/pi";
import { CgCloseO } from "react-icons/cg";
import { BiBarChartSquare } from "react-icons/bi";
import { IoCalendarOutline } from "react-icons/io5";

export const getHeaderIcon = (path) => {
  switch (path) {
    case "/demos":
    case "/showrooms":
      return <PiWrenchFill />;
    case "/blocks":
      return <CgCloseO />;
    case "/reports":
      return <BiBarChartSquare />;
    case "/calendar":
      return <IoCalendarOutline />;
    default:
      return;
  }
};

export const getHeaderTitle = (path) => {
  switch (path) {
    case "/demos":
      return "Demo Management";
    case "/showrooms":
      return "Showroom Management";
    case "/blocks":
      return "Block Management";
    case "/calendar":
      return "Calendar";
    case "/reports":
      return "Reports";
    default:
      return;
  }
};

export const getHeaderClass = (path) => {
  switch (path) {
    case "/demos":
      return "demos";
    case "/showrooms":
      return "showrooms";
    case "/blocks":
      return "blocks";
    case "/calendar":
      return "calendar";
    case "/reports":
      return "reports";
    default:
      return "";
  }
};
