import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import Dropdown from "../../../../components/Dropdown/Dropdown";
import { logReportTypes } from "../../../../db/reportTypes";
import "./logFilter.css";
import Button from "../../../../components/Button/Button";
import { formatDate } from "../../../../utils/formatDateTime";
import { setLogReport } from "../../../../redux-sagas/actions";
import { useDispatch } from "react-redux";
import { getTranslation } from "../../../../utils/getTranslation";
import { useTranslation } from "react-i18next";
import "dayjs/locale/es";

const LogFilter = ({ logQueryParams, setLogQueryParams }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [filters, setFilters] = useState({});

  const handleDateChange = (name, newValue) => {
    setFilters({ ...filters, [name]: newValue });
    setLogQueryParams({ ...logQueryParams, [name]: formatDate(newValue) });
  };

  const handleDropdownChange = (e) => {
    dispatch(setLogReport({ data: [], totalRecords: 0, totalPages: 0 }));
    setFilters({ ...filters, logType: e.target.value });
    setLogQueryParams({ ...logQueryParams, logType: e.target.value });
  };

  const handleClearFilters = () => {
    setFilters({});
    setLogQueryParams({
      ...logQueryParams,
      startDate: "",
      endDate: "",
      logType: ""
    });
  };

  return (
    <div className="log row">
      <div className="row px-0 col-xl-10 col-lg-12">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={i18n.language}
        >
          <DatePicker
            className="calendar-input col-md-6 col-lg-4 ps-0 pe-sm-0 pe-xl-5 pe-md-4"
            onChange={(newValue) => handleDateChange("startDate", newValue)}
            value={filters.startDate ?? null}
            label={getTranslation("Start Date", t)}
          />
          <DatePicker
            className="calendar-input col-md-6 col-lg-4 ps-0 pe-sm-0 pe-xl-5 pe-md-0 pe-lg-4"
            onChange={(newValue) => handleDateChange("endDate", newValue)}
            value={filters.endDate ?? null}
            label={getTranslation("End Date", t)}
          />
        </LocalizationProvider>
        <Dropdown
          className="col-md-6 col-lg-4 ps-0 pe-sm-0 pe-xl-5 pe-md-4 pe-lg-0 pb-3 pb-xl-0"
          label={getTranslation("Report type", t)}
          data={logReportTypes.map((item) => ({
            ...item,
            name: getTranslation(item.name, t)
          }))}
          value={filters.logType}
          handleChange={handleDropdownChange}
          valueField="value"
          textField="name"
        />
      </div>
      <span className="col-lg-2 col-md-3 col-sm-12 px-0">
        <Button onClick={handleClearFilters} type="secondary">
          {getTranslation("Clean", t)}
        </Button>
      </span>
    </div>
  );
};

export default LogFilter;
